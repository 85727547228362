import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, MouseEvent, TouchEvent, useRef, useState } from 'react'

import { PROPOSED_SWITCH_LANGUAGE, languagesList } from '@promova/config'
import useRouterLocales from '@promova/utils/customHooks/useRouterLocales'

import { useClickOutside } from '@hooks/customHooks'

import { sendAnalytics } from '@utils/analytics'

import styles from './language_switch.module.scss'

type Props = {
  popupPosition?: 'top' | 'bottom'
  availableLocales?: string[]
}

const LanguageSwitch: FC<Props> = ({
  popupPosition = 'bottom',
  availableLocales,
}) => {
  const [isSwitcherPopupOpen, setIsSwitcherPopupOpen] = useState(false)
  const { asPath, locale } = useRouter()
  const routerLocales = useRouterLocales()
  const containerRef = useRef<HTMLButtonElement>(null)

  const locales = availableLocales?.length ? availableLocales : routerLocales

  const closeSwitcherPopup = () => {
    setIsSwitcherPopupOpen(false)
  }
  const toggleOpenSwitcherPopup = () =>
    setIsSwitcherPopupOpen((isOpen) => !isOpen)

  useClickOutside(isSwitcherPopupOpen, containerRef, closeSwitcherPopup)

  const onChooseLanguage = (
    e: TouchEvent<HTMLAnchorElement> | MouseEvent<HTMLAnchorElement>
  ) => {
    const lang = e?.currentTarget?.dataset?.lang

    if (lang === locale) {
      e.preventDefault()
      return
    }

    localStorage.setItem(PROPOSED_SWITCH_LANGUAGE, 'true')

    sendAnalytics({
      eventName: 'gen_website_language_changed',
      data: {
        from: locale,
        to: lang,
        page_path: asPath,
      },
    })
  }

  const popupClassNames = classNames(
    styles.popup,
    styles[`popup_${popupPosition}`],
    {
      [styles.popup_active]: isSwitcherPopupOpen,
    }
  )

  return (
    <div className={styles.container}>
      <button
        type="button"
        ref={containerRef}
        className={styles.button}
        onClick={toggleOpenSwitcherPopup}
      >
        {languagesList[locale || '']?.shortLabel || locale}
      </button>

      <ul className={popupClassNames}>
        {locales?.map((localePrefix) =>
          languagesList[localePrefix]?.label ? (
            <li key={localePrefix} className={styles.popup_item}>
              <Link href={asPath} locale={localePrefix}>
                {/* eslint-disable-next-line */}
                <a data-lang={localePrefix} onClick={onChooseLanguage}>
                  {languagesList[localePrefix]?.label || localePrefix}
                </a>
              </Link>
            </li>
          ) : null
        )}
      </ul>
    </div>
  )
}

export default LanguageSwitch
