import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { THREE_DAYS_IN_MILLISECONDS } from '@constants_folder/common'
import { StrapiCtaBannerList } from '@constants_folder/strapi_urls'

import { useCtaMediaBannersListQuery } from '@hooks/CTA/useCtaMediaBannersListQuery'
import useBreakpoints from '@hooks/useBreakpoints'
import useGlobalScroll from '@hooks/useGlobalScroll'
import useLandingInfo from '@hooks/useLandingInfo'

import { shouldRenderCTAByIncludedExcludedUrls } from '@utils/shouldRenderCTAByIncludedExcludedUrls'

import CtaDownloadAppQrPopup from '@modules/CTA/CtaDownloadAppQrPopup/CtaDownloadAppQrPopup'
import { useCtaQueue } from '@modules/CTA/CtaQueueProvider/CtaQueueContext'
import type { MediaBannerListResponse } from '@modules/CTA/types/CTAMediaBannerListTypes'

const mediaBannerPopulateTemplate = {
  'modules.media-banner': {
    populate: {
      button: '*',
      media: {
        fields: ['alternativeText', 'caption', 'ext', 'mime', 'url'],
      },
      backgroundColor: '*',
    },
  },
}

const populateTemplate = {
  banners: {
    on: { ...mediaBannerPopulateTemplate },
  },
  urlList: '*',
}

const SCROLL_THRESHOLD = 50

export const useCtaMediaBannersList = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [willRender, setWillRender] = useState<boolean | undefined>(undefined)
  const { locale, events } = useRouter()
  const { clearCtaQueue, setAsCurrentCta } = useCtaQueue()
  const { lg: isDesktop } = useBreakpoints()
  const { LANDING_PATH } = useLandingInfo()
  const { isScrolled, resetScroll } = useGlobalScroll(SCROLL_THRESHOLD)

  const { data: result } = useCtaMediaBannersListQuery<MediaBannerListResponse>(
    {
      locale,
      populate: populateTemplate,
      fields: ['locale', 'slug'],
      enabled: isDesktop,
    }
  )

  const firstBannerSlugToShow = result?.data?.find((banner) =>
    shouldRenderCTAByIncludedExcludedUrls({
      currentUrl: LANDING_PATH,
      includedUrls: banner.attributes.urlList?.urlsToInclude,
      excludedUrls: banner.attributes.urlList?.urlsToExclude,
    })
  )

  useEffect(() => {
    const checkIfBannerWasClosed = (): boolean => {
      const bannerSlug = firstBannerSlugToShow?.attributes?.slug
      if (!bannerSlug) return true

      const jsonItem = localStorage.getItem(bannerSlug)

      if (!jsonItem) return false

      const item = JSON.parse(jsonItem)
      const expiredAt = parseInt(item?.expiry, 10)

      if (Date.now() > expiredAt) {
        localStorage.removeItem(bannerSlug)

        return false
      }
      return true
    }

    if (!isOpen) {
      const bannerWasClosed = checkIfBannerWasClosed()
      setWillRender(!bannerWasClosed)
    }
  }, [firstBannerSlugToShow?.attributes?.slug, isOpen])

  useEffect(() => {
    if (isScrolled && willRender) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [isScrolled, willRender])

  const handleCloseMediaBanner = () => {
    const bannerSlug = firstBannerSlugToShow?.attributes?.slug
    if (!bannerSlug) return

    setIsOpen(false)
    setWillRender(false)

    setAsCurrentCta({
      id: StrapiCtaBannerList.DOWNLOAD_APP_QR_CODE,
      ctaComponent: <CtaDownloadAppQrPopup />,
    })

    localStorage.setItem(
      bannerSlug,
      JSON.stringify({
        value: true,
        expiry: Date.now() + THREE_DAYS_IN_MILLISECONDS,
      })
    )
  }

  useEffect(() => {
    const handleRouteChange = () => {
      resetScroll()
      clearCtaQueue()
    }

    events.on('routeChangeStart', handleRouteChange)
    events.on('routeChangeComplete', handleRouteChange)

    return () => {
      events.off('routeChangeStart', handleRouteChange)
      events.off('routeChangeComplete', handleRouteChange)
    }
  }, [clearCtaQueue, events, resetScroll])

  return {
    data: firstBannerSlugToShow,
    isOpen,
    willRender,
    onClose: handleCloseMediaBanner,
  }
}
