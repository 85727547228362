import { FC } from 'react'

import { CtaPopupAllPagesAttributes } from '@_types/strapi/singleTypes'

import CtaModal from '@modules/CTA/CtaPopupAllPages/CtaModal'

interface Props {
  data: CtaPopupAllPagesAttributes | undefined
  isOpen: boolean
  handleClose: () => void
}

const CtaPopupAllPages: FC<Props> = ({ data, isOpen, handleClose }) => {
  if (!data || !isOpen) return null

  return (
    <CtaModal popup={data} isModalOpen={isOpen} handleClose={handleClose} />
  )
}

export default CtaPopupAllPages
