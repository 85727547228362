import { RefObject, useCallback, useEffect, useState } from 'react'

interface Props {
  videoRef: RefObject<HTMLVideoElement>
}

interface UseVideoPlayer {
  onPlay: () => void
  onStop: () => void
  onChangePosition: (time: number) => void
  onMute: () => void
  playing: boolean
  finished: boolean
  position: number | undefined
  duration: number | undefined
  muted: boolean
  toggleFullscreen: () => void
}

const useVideo = ({ videoRef }: Props): UseVideoPlayer => {
  const video = videoRef.current

  const [playing, setPlaying] = useState(false)
  const [finished, setFinished] = useState(false)
  const [position, setPosition] = useState(0)
  const [duration, setDuration] = useState(0)
  const [muted, setMuted] = useState(true)

  const handlePlay = useCallback(async () => {
    try {
      await video?.play()
    } catch {
      //
    }
  }, [video])

  const handleStop = useCallback(() => {
    video?.pause()
  }, [video])

  const handleMute = useCallback(() => {
    if (video) {
      video.muted = !video.muted
    }
  }, [video])

  const handleChangePosition = useCallback(
    (time: number) => {
      if (video) {
        video.currentTime = time
      }
    },
    [video]
  )

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      video?.requestFullscreen().catch((err) => {
        console.log(err)
      })
    } else {
      document.exitFullscreen()
    }
  }

  useEffect(
    () => () => {
      handleStop()
      setPlaying(false)
    },
    [handleStop]
  )

  useEffect(() => {
    const handleOnLoad = () => {
      setDuration(Math.ceil(video?.duration || 0))
    }

    const handleSetPosition = () => {
      setPosition(Math.ceil(video?.currentTime || 0))
    }
    const handleMuted = () => {
      if (video) setMuted(video.muted)
    }

    const onPlay = () => setPlaying(true)
    const onPause = () => setPlaying(false)
    const onFinished = () => setFinished(true)

    video?.addEventListener('canplay', handleOnLoad)
    video?.addEventListener('play', onPlay)
    video?.addEventListener('pause', onPause)
    video?.addEventListener('ended', onFinished)
    video?.addEventListener('timeupdate', handleSetPosition)
    video?.addEventListener('volumechange', handleMuted)

    return () => {
      video?.removeEventListener('canplay', handleOnLoad)
      video?.removeEventListener('play', onPlay)
      video?.removeEventListener('pause', onPause)
      video?.removeEventListener('ended', onFinished)
      video?.removeEventListener('timeupdate', handleSetPosition)
      video?.removeEventListener('volumechange', handleMuted)
    }
  }, [video])

  return {
    onPlay: handlePlay,
    onStop: handleStop,
    onChangePosition: handleChangePosition,
    onMute: handleMute,
    playing,
    finished,
    position,
    duration,
    muted,
    toggleFullscreen,
  }
}

export default useVideo
