import { ColorObjectTypes } from '@utils/strapiCommons/types'

export const colorsLibrary: ColorObjectTypes = {
  Purple: {
    20: '#F2F4FF',
    30: '#ECEEFF',
    50: '#DFE3FF',
    70: '#D2D9FF',
    100: '#BEC8FF',
  },
  Pink: {
    20: '#FDECF6',
    30: '#FCE2F2',
    50: '#FACFE9',
    70: '#F8BDE0',
    100: '#F5A0D2',
  },
  Green: {
    20: '#F4F9E7',
    30: '#EEF5DB',
    50: '#E3EFC3',
    70: '#D9E9AB',
    100: '#C8DF87',
  },
  Yellow: {
    20: '#FFFCDC',
    30: '#FFFBCB',
    50: '#FFF8A7',
    70: '#FFF584',
    100: '#FFF050',
  },
  Turquoise: {
    50: '#90ECE0',
    80: '#4EE0CE',
    100: '#22D8C2',
  },
  Emerald: {
    50: '#BCF0D0',
    80: '#93E6B5',
    100: '#78E0A2',
  },
  Wisteria: {
    50: '#DECEF2',
    80: '#C9B1E9',
    100: '#BC9DE4',
  },
  'Gold Crayola': {
    50: '#F1DBBA',
    80: '#E8C590',
    100: '#E2B674',
  },
  Salmon: {
    50: '#F7CACD',
    80: '#F2AAAF',
    100: '#EF959B',
  },
  'Blue Bell': {
    50: '#D7D4E2',
    80: '#BFBBD1',
    100: '#AFAAC5',
  },
  'Silver Pink': {
    50: '#DBD1D2',
    80: '#C5B6B7',
    100: '#B6A4A5',
  },
  'Deep Champagne': {
    50: '#FDE3C3',
    80: '#FCD3A0',
    100: '#FBC888',
  },
  Papyrus: {
    100: '#EBE0D3',
  },
  Black: {
    5: 'rgba(0, 0, 0, 0.05)',
    10: 'rgba(0, 0, 0, 0.1)',
    20: 'rgba(0, 0, 0, 0.2)',
    30: 'rgba(0, 0, 0, 0.3)',
    50: 'rgba(0, 0, 0, 0.5)',
    70: 'rgba(0, 0, 0, 0.7)',
    80: 'rgba(0, 0, 0, 0.8)',
    100: '#000',
  },
  White: {
    20: 'rgba(255, 255, 255, 0.2)',
    30: 'rgba(255, 255, 255, 0.3)',
    50: 'rgba(255, 255, 255, 0.5)',
    100: '#fff',
  },
  Gray: {
    4: '#F5F5F5',
    7: '#EEEEEE',
    12: '#E0E0E0',
    100: '#E0E0E0',
  },
  Negative: {
    100: '#FF7070',
  },
  Warning: {
    100: '#FFC043',
  },
}

export const fontsLibrary = {
  Nekst24: {
    fontSize: 24,
    fontSizeMobile: 24,
  },
  Nekst40: {
    fontSize: 40,
    fontSizeMobile: 30,
  },
  Nekst50: {
    fontSize: 50,
    fontSizeMobile: 40,
  },
  Nekst70: {
    fontSize: 70,
    fontSizeMobile: 50,
  },
  Nekst100: {
    fontSize: 100,
    fontSizeMobile: 60,
  },
  Nekst120: {
    fontSize: 120,
    fontSizeMobile: 60,
  },
  Manrope16: {
    fontSize: 16,
    fontSizeMobile: 16,
  },
  Manrope18: {
    fontSize: 18,
    fontSizeMobile: 16,
  },
  Manrope24: {
    fontSize: 24,
    fontSizeMobile: 20,
  },
  Manrope50: {
    fontSize: 50,
    fontSizeMobile: 34,
  },
}

export const populateTemplateForRelatedPages = {
  seo: {
    fields: ['metaDescription'],
  },
  localizations: {
    fields: ['locale', 'title'],
    populate: {
      seo: {
        fields: ['metaDescription'],
      },
    },
  },
}
