import { useRouter } from 'next/router'
import { useEffect } from 'react'

interface Props {
  // Don't return false from this function, it will prevent navigation.
  // use it only for side effects
  onBeforeNavigationChange: () => void
}

export const useBeforeNavigationChange = ({
  onBeforeNavigationChange,
}: Props) => {
  const router = useRouter()

  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        // Will run when leaving the current page; on back/forward actions.
        onBeforeNavigationChange()
      }
      return true
    })

    return () => {
      router.beforePopState(() => true)
    }
  }, [router, onBeforeNavigationChange])
}
