import classNames from 'classnames'
import { intervalToDuration, parseISO } from 'date-fns'
import { FC, useEffect, useState } from 'react'

import { ColorDataTypes } from '@utils/strapiCommons/types'
import { getColor } from '@utils/strapiCommons/utils'

import styles from './timer_block.module.scss'

const Delimiter = () => <span className={styles.timer}>&nbsp;:&nbsp;</span>

const initialDate = new Date()

type Props = {
  dateStart: string
  dateEnd: string
  subtitle?: string
  color?: ColorDataTypes
  disabled?: boolean
}

const defaultColor: ColorDataTypes = {
  name: 'Negative',
  opacity: 100,
}

const TimerBlock: FC<Props> = ({
  dateStart,
  dateEnd,
  subtitle,
  color,
  disabled,
}) => {
  const [currentDate, setCurrentDate] = useState(initialDate)

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (dateStart && dateEnd && initialDate > parseISO(dateStart)) {
      interval = setInterval(() => {
        const now = new Date()
        setCurrentDate(now)
        if (now > parseISO(dateEnd)) {
          clearInterval(interval)
        }
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [dateEnd, dateStart])

  if (disabled || !dateStart || !dateEnd || currentDate < parseISO(dateStart))
    return null

  const blockColor = getColor(color || defaultColor)
  const isColorBlack = color?.name === 'Black'

  const isTimerEnded = currentDate > parseISO(dateEnd)

  const { days, hours, minutes, seconds } = intervalToDuration({
    start: currentDate,
    end: parseISO(dateEnd),
  })

  const wrapperClassNames = classNames(styles.wrapper, {
    [styles.wrapper_white]: isColorBlack,
  })

  const subtitleClassNames = classNames(styles.subtitle, {
    [styles.subtitle_white]: isColorBlack,
  })

  return (
    <div className={styles.block} style={{ backgroundColor: blockColor }}>
      <div className={wrapperClassNames}>
        <span className={styles.timer}>{isTimerEnded ? '0' : days}d</span>
        <Delimiter />
        <span className={styles.timer}>{isTimerEnded ? '0' : hours}h</span>
        <Delimiter />
        <span className={styles.timer}>{isTimerEnded ? '0' : minutes}m</span>
        <Delimiter />
        <span className={styles.timer}>{isTimerEnded ? '0' : seconds}s</span>
      </div>
      {subtitle && (
        <div
          className={subtitleClassNames}
          dangerouslySetInnerHTML={{ __html: subtitle as string }}
        />
      )}
    </div>
  )
}

export default TimerBlock
