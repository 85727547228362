import { Dispatch, SetStateAction, useEffect, useState } from 'react'

const browserStorages = {
  localStorage: 'localStorage',
  sessionStorage: 'sessionStorage',
} as const

type StorageType = keyof typeof browserStorages

type Params<T> = {
  storageType: StorageType
  key: string
  initialValue: T | (() => T)
  timeToLive: number | null // ! "timeToLive" value must be in seconds
}

type Item<T> = {
  value: T
  expiry: number | null
}

export default function useBrowserStorage<T>(
  params: Params<T>
): [T, Dispatch<SetStateAction<T>>] {
  const { storageType, key, initialValue, timeToLive } = params
  const isLocalStorage = storageType === browserStorages.localStorage

  const [value, setValue] = useState<T>(() => {
    let jsonValue: string | null = null

    if (typeof window !== 'undefined') {
      jsonValue = isLocalStorage
        ? localStorage.getItem(key)
        : sessionStorage.getItem(key)
    }

    if (jsonValue != null) {
      const item: Item<T> = JSON.parse(jsonValue)

      if (item.expiry === null) {
        return item.value
      }

      if (Date.now() > item.expiry) {
        if (isLocalStorage) {
          localStorage.removeItem(key)
        } else {
          sessionStorage.removeItem(key)
        }

        return typeof initialValue === 'function'
          ? (initialValue as () => T)()
          : initialValue
      }

      return item.value
    }

    return typeof initialValue === 'function'
      ? (initialValue as () => T)()
      : initialValue
  })

  useEffect(() => {
    const item = {
      value,
      expiry: timeToLive ? Date.now() + timeToLive * 1000 : null,
    }

    if (isLocalStorage) {
      const jsonStorageItem = localStorage.getItem(key)
      if (jsonStorageItem) {
        const lsItem = JSON.parse(jsonStorageItem)
        if (lsItem.value === value) {
          return
        }
      }

      localStorage.setItem(key, JSON.stringify(item))
    } else {
      sessionStorage.setItem(key, JSON.stringify(item))
    }
  }, [key, value, timeToLive, isLocalStorage])

  return [value, setValue]
}
