import { DEFAULT_LOCALE } from '@constants_folder/common'
import { CTA_MEDIA_BANNERS_LIST } from '@constants_folder/strapi_urls'

import { StrapiBaseCollectionTypeResponse } from '@_types/strapi/common'

import { HOURS_24 } from '@hooks/dataFetching/constants'
import { useQueryFactory } from '@hooks/dataFetching/useQueryFactory'
import { fetchStrapiViaProxy } from '@hooks/dataFetching/utils'

interface Props {
  locale?: string | undefined
  populate?: Record<string, any> | string
  filters?: Record<string, any>
  fields?: string | string[]
  enabled?: boolean
}

export const useCtaMediaBannersListQuery = <T>({
  locale = DEFAULT_LOCALE,
  populate,
  filters = {},
  fields,
  enabled = true,
}: Props) =>
  useQueryFactory(
    [CTA_MEDIA_BANNERS_LIST, locale],
    (): Promise<StrapiBaseCollectionTypeResponse<T>> =>
      fetchStrapiViaProxy({
        url: CTA_MEDIA_BANNERS_LIST,
        urlParamsObject: {
          populate: populate || 'deep',
          locale,
          pagination: {
            limit: '-1',
          },
          filters: {
            ...filters,
          },
          sort: 'updatedAt:DESC',
          fields,
        },
      })
  )({
    enabled,
    cacheTime: HOURS_24,
    staleTime: HOURS_24,
  })
