import { StrapiCtaBannerList } from '@constants_folder/strapi_urls'

import { CtaWithRunningTextType } from '@_types/strapi/singleTypes'

import { useCtaBannerListQuery } from '@hooks/dataFetching/CTA/useCtaBannerListQuery'

export const useCtaWithTickerQuery = ({
  locale,
  enabled = true,
}: {
  locale: string | undefined
  enabled?: boolean
}) =>
  useCtaBannerListQuery<CtaWithRunningTextType>({
    locale,
    enabled,
    slug: StrapiCtaBannerList.CTA_WITH_RUNNING_TEXT,
  })
