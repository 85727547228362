import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { subscribeToNewsletter } from '@api/subscribeToNewsletter'

import { Event } from '@constants'
import { USER_TIMEZONE } from '@constants_folder/common'
import {
  RetenoChannelType,
  RetenoFieldId,
  RetenoGroup,
} from '@constants_folder/reteno'

import { useCtaEmailSubscribeQuery } from '@hooks/CTA/useCtaEmailSubscribeQuery'
import useBreakpoints from '@hooks/useBreakpoints'
import useBrowserLanguage from '@hooks/useBrowserLanguage/useBrowserLanguage'
import useGlobalScroll from '@hooks/useGlobalScroll'
import useLandingInfo from '@hooks/useLandingInfo'

import { sendAnalytics } from '@utils/analytics'
import { shouldRenderCTAByIncludedExcludedUrls } from '@utils/shouldRenderCTAByIncludedExcludedUrls'

import {
  EmailSubscribeFormValues,
  EmailSubscribeListResponse,
} from '@modules/CTA/CtaEmailSubscribe/types/CTAEmailSubscribeListTypes'
import {
  checkIfBannerWasClosed,
  getTimeFromLocalStorage,
  removeTimeSlugFromLocalStorage,
  setClosedStatusForBanner,
} from '@modules/CTA/CtaEmailSubscribe/utils'

interface UseCtaEmailSubscribe {
  isOpen: boolean
  timerStartCount: number | null
  initialTimerSecondsFromCms?: number
  isSubmitError: boolean
  resetSubmitError: () => void
  closeBanner: (slug?: string) => void
  onSubscribe: (
    data: EmailSubscribeFormValues
  ) => Promise<{ message: string } | void>
  data?: EmailSubscribeListResponse
}

const bannerPopulateTemplate = {
  'cta.cta-email-subscribe': {
    populate: {
      variant: '*',
      timerBgColor: '*',
      mainBlockBgColor: '*',
      media: {
        fields: ['alternativeText', 'caption', 'url'],
      },
      secondScreenMedia: {
        fields: ['alternativeText', 'caption', 'url'],
      },
      button: '*',
    },
  },
}

const populateTemplate = {
  banner: {
    on: { ...bannerPopulateTemplate },
  },
  urlList: '*',
}

const useCtaEmailSubscribe = (): UseCtaEmailSubscribe => {
  const [isOpen, setIsOpen] = useState(false)
  const [isSubmitError, setIsSubmitError] = useState(false)
  const [timerStartCount, setTimerStartCount] = useState<number | null>(null)

  const { isScrolled, resetScroll } = useGlobalScroll()
  const { locale, events } = useRouter()
  const { LANDING_PATH } = useLandingInfo()
  const { lg: isDesktop } = useBreakpoints()
  const userBrowserLang = useBrowserLanguage()

  const { data: result } =
    useCtaEmailSubscribeQuery<EmailSubscribeListResponse>({
      locale,
      populate: populateTemplate,
      fields: ['locale', 'slug'],
      enabled: isDesktop,
    })

  const firstBannerSlugToShow = result?.data?.find((banner) =>
    shouldRenderCTAByIncludedExcludedUrls({
      currentUrl: LANDING_PATH,
      includedUrls: banner.attributes.urlList?.urlsToInclude || [],
      excludedUrls: banner.attributes.urlList?.urlsToExclude || [],
    })
  )

  const bannerSlug = firstBannerSlugToShow?.attributes?.slug
  const initialTimerSecondsFromCms =
    firstBannerSlugToShow?.attributes?.banner?.[0]?.timerSeconds

  const closeBanner = (slug?: string) => {
    setIsOpen(false)
    setClosedStatusForBanner(slug)
    removeTimeSlugFromLocalStorage(slug)
  }

  const resetSubmitError = () => {
    setIsSubmitError(false)
  }

  const onSubscribe = async (
    data: EmailSubscribeFormValues
  ): Promise<{ message: string } | void> => {
    const retenoData = {
      formType: RetenoGroup.POPUP_ORGANIC_EMAIL,
      groups: [RetenoGroup.POPUP_ORGANIC_EMAIL],
      contact: {
        languageCode: userBrowserLang,
        timeZone: USER_TIMEZONE,
        channels: [
          {
            type: RetenoChannelType.EMAIL,
            value: data?.email,
          },
        ],
        fields: [
          { id: RetenoFieldId.ORGANIC, value: RetenoGroup.POPUP_ORGANIC_EMAIL },
        ],
      },
    }

    try {
      setIsSubmitError(false)

      const subscribeResponse = await subscribeToNewsletter(retenoData)

      if (subscribeResponse?.data.message === 'OK') {
        sendAnalytics({
          eventName: Event.GEN_NEWSLETTER_SUBSCRIBED,
          data: {
            mail: data.email,
          },
        })
      }

      return subscribeResponse?.data
    } catch (error) {
      let reason = 'Unknown error'

      if (error instanceof AxiosError || error instanceof Error) {
        reason = error.message

        if (error?.message !== 'Network Error') {
          // preventing opening two toasts (global and local) of network error
          setIsSubmitError(true)
        }
      }

      sendAnalytics({
        eventName: Event.GEN_NEWSLETTER_SUBSCRIPTION_FAILED,
        data: {
          reason,
        },
      })
    }
  }

  useEffect(() => {
    if (!bannerSlug) return

    const bannerWasClosed = checkIfBannerWasClosed(bannerSlug)

    if (!bannerWasClosed && isScrolled) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [bannerSlug, isScrolled])

  useEffect(() => {
    const time = getTimeFromLocalStorage(bannerSlug)

    if (!initialTimerSecondsFromCms) {
      setTimerStartCount(null)
    } else {
      setTimerStartCount(time ?? initialTimerSecondsFromCms)
    }
  }, [bannerSlug, initialTimerSecondsFromCms])

  useEffect(() => {
    const handleRouteChange = () => {
      resetScroll()
    }

    events.on('routeChangeStart', handleRouteChange)
    events.on('routeChangeComplete', handleRouteChange)

    return () => {
      events.off('routeChangeStart', handleRouteChange)
      events.off('routeChangeComplete', handleRouteChange)
    }
  }, [events, resetScroll])

  return {
    isOpen,
    timerStartCount,
    initialTimerSecondsFromCms,
    isSubmitError,
    resetSubmitError,
    onSubscribe,
    closeBanner,
    data: firstBannerSlugToShow?.attributes,
  }
}

export default useCtaEmailSubscribe
