export const sleep = (time: number) =>
  new Promise((res) => {
    setTimeout(res, time)
  })

export const getLSValue = <T>(key: string, defaultValue?: T): T => {
  try {
    const value = localStorage.getItem(key)

    return value ? JSON.parse(value) : defaultValue
  } catch {
    return defaultValue as T
  }
}

export const setLSValue = (key: string, value: unknown) => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch {
    //
  }
}

export const clearStringFromTags = (string: string) =>
  string?.replaceAll(/(<([^>]+)>)/gi, '')

export const clearNonBreakingSpaces = (string: string) =>
  string?.replaceAll(/&nbsp;/gi, ' ')

export const zeroFormattedNumber = (number: number) =>
  String(number).padStart(2, '0')
