import classNames from 'classnames'
import { FC, MouseEvent } from 'react'

import { storeLinks } from '@constants'

import { createLinkWithUtms } from '@utils/createLinkWithUtms'

import AppStoreIcon from '@icons/App_store.svg'
import AppleIcon from '@icons/Apple.svg'
import HuaweiIcon from '@icons/Huawei.svg'
import PlayStoreIcon from '@icons/Play_market.svg'

import styles from './store_button.module.scss'

type StoreType = 'app-store' | 'apple' | 'google' | 'huawei'

export type StoreButtonProps = {
  type?: StoreType
  theme?: 'default' | 'circle'
  link?: string
  fullWidthMobile?: boolean
  wrapperClassName?: string
  className?: string
  onClick?: (e: MouseEvent<HTMLElement>) => void | any
  [key: string]: any
}

const storeInfo = {
  'app-store': {
    title: 'Download on the',
    name: 'AppStore',
    url: storeLinks.universal,
    titleClassname: styles.title,
    Icon: <AppStoreIcon className={styles.store_icon} />,
  },
  apple: {
    title: 'Download for',
    name: 'Mac OS',
    url: storeLinks.universal,
    titleClassname: styles.title,
    Icon: <AppleIcon className={styles.store_icon} />,
  },
  google: {
    title: 'Get in on',
    name: 'Google Play',
    url: storeLinks.google,
    titleClassname: styles.title_uppercase,
    Icon: <PlayStoreIcon className={styles.store_icon} />,
  },
  huawei: {
    title: 'Explore it on',
    name: 'AppGallery',
    url: storeLinks.huawei,
    titleClassname: styles.title_uppercase,
    Icon: <HuaweiIcon className={styles.store_icon} />,
  },
}

const Icon: FC<{ type: StoreType }> = ({ type }) => storeInfo[type].Icon

const StoreButton: FC<StoreButtonProps> = ({
  type = 'app-store',
  theme = 'default',
  className = '',
  wrapperClassName = '',
  fullWidthMobile = false,
  link,
  onClick,
  ...rest
}) => {
  const buttonWrapperClassName = classNames(styles.store_button, {
    [wrapperClassName]: !!wrapperClassName,
  })

  const buttonClassName = classNames(
    styles.button_wrapper,
    styles[`theme_${theme}`],
    {
      [styles.button_wrapper_fullwidth_mobile]: fullWidthMobile,
      [className]: !!className,
    }
  )

  const imageClassName = classNames(styles[`store_image_${theme}`])

  return (
    <a
      href={link || createLinkWithUtms(storeInfo[type].url)}
      className={buttonWrapperClassName}
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      <button
        type="button"
        className={buttonClassName}
        onClick={onClick}
        {...rest}
      >
        <figure className={imageClassName}>
          <Icon type={type} />
        </figure>
        {theme === 'default' && (
          <div className={styles.store_info}>
            <span className={storeInfo[type].titleClassname}>
              {storeInfo[type].title}
            </span>
            <span className={styles.store_name}>{storeInfo[type].name}</span>
          </div>
        )}
      </button>
    </a>
  )
}

export default StoreButton
