import { THREE_DAYS_IN_MILLISECONDS } from '@constants_folder/common'
import { ALREADY_SHOWN, TIME_LEFT } from '@constants_folder/storageKeys'

export const removeTimeSlugFromLocalStorage = (slug?: string) => {
  if (typeof window === 'undefined') return
  if (!slug) return

  const timeSlug = `${slug}-${TIME_LEFT}`

  localStorage.removeItem(timeSlug)
}

export const addTimeSlugToLocalStorage = (slug?: string, value?: string) => {
  if (typeof window === 'undefined') return
  if (!slug || !value) return

  const timeSlug = `${slug}-${TIME_LEFT}`

  localStorage.setItem(timeSlug, value)
}

export const getTimeFromLocalStorage = (slug?: string): number | null => {
  if (typeof window === 'undefined') return null
  if (!slug) return null

  const key = `${slug}-${TIME_LEFT}`
  const timeFromLs = localStorage.getItem(key)
  if (timeFromLs) {
    return parseInt(timeFromLs, 10)
  }

  return null
}

export const setClosedStatusForBanner = (
  slug?: string,
  expiryTimeInMilliseconds?: number
) => {
  if (typeof window === 'undefined') return
  if (!slug) return

  const lsKey = `${slug}-${ALREADY_SHOWN}`
  localStorage.setItem(
    lsKey,
    JSON.stringify({
      value: true,
      expiry:
        Date.now() + (expiryTimeInMilliseconds || THREE_DAYS_IN_MILLISECONDS),
    })
  )
}

export const checkIfBannerWasClosed = (slug?: string): boolean => {
  if (!slug) return true

  const lsKey = `${slug}-${ALREADY_SHOWN}`
  const jsonItem = localStorage.getItem(lsKey)

  if (!jsonItem) return false

  removeTimeSlugFromLocalStorage(slug)
  const item = JSON.parse(jsonItem)
  const expiredAt = parseInt(item?.expiry, 10)

  if (Date.now() > expiredAt) {
    localStorage.removeItem(lsKey)
    return false
  }
  return true
}
