import { FC } from 'react'

import { CtaBannerForArticleAttributes } from '@_types/strapi/singleTypes'

import Button from '@elements/ButtonV2/Button'

import styles from './cta_tutoring_banner_mobile.module.scss'

interface Props {
  data: CtaBannerForArticleAttributes
}

export const CtaTutoringBannerMobileOnly: FC<Props> = ({ data }) => (
  <div className={styles.cta}>
    <span className={styles.title}> {data.bannerTitle}</span>
    <Button
      {...data?.button}
      link={data?.button?.href}
      className={styles.btn}
      type="outlined"
    >
      {data.button?.label}
    </Button>
  </div>
)
