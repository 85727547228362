interface Time {
  hours: number
  minutes: number
  seconds: number
}

const secondsToTime = (sec: number): Time => {
  const hours = Math.floor(sec / (60 * 60))

  const divisorForMinutes = sec % (60 * 60)
  const minutes = Math.floor(divisorForMinutes / 60)

  const divisorForSeconds = divisorForMinutes % 60
  const seconds = Math.ceil(divisorForSeconds)

  const result = {
    hours,
    minutes,
    seconds,
  }
  return result
}

export default secondsToTime
