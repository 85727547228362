import { useRouter } from 'next/router'
import { FC } from 'react'

import { StrapiCtaBannerList } from '@constants'

import { CtaBannerForArticleAttributes } from '@_types/strapi/singleTypes'

import { useCtaBannerListQuery } from '@hooks/dataFetching/CTA/useCtaBannerListQuery'
import useBreakpoints from '@hooks/useBreakpoints'
import useLandingInfo from '@hooks/useLandingInfo'

import { shouldRenderCTAByIncludedExcludedUrls } from '@utils/shouldRenderCTAByIncludedExcludedUrls'

import { CtaTutoringBannerMobileOnly } from '@modules/CTA/CtaTutoringBannerMobile/CtaTutoringBannerMobileOnly'

export const CtaTutoringBannerMobileOnlyWrap: FC = () => {
  const { locale } = useRouter()
  const { xs: isMobile } = useBreakpoints()
  const { data, isLoading, isError } =
    useCtaBannerListQuery<CtaBannerForArticleAttributes>({
      locale,
      enabled: isMobile,
      slug: StrapiCtaBannerList.CTA_TUTORING_POPUP,
    })
  const { LANDING_PATH } = useLandingInfo()

  const isDataStatusProblematic = isLoading || isError || !data
  const isCTAVisibleBasedOnUrl = shouldRenderCTAByIncludedExcludedUrls({
    currentUrl: LANDING_PATH,
    includedUrls: data?.urlsToInclude || [],
    excludedUrls: data?.urlsToExclude || [],
  })

  if (
    data?.isDisabled ||
    !isMobile ||
    isDataStatusProblematic ||
    !isCTAVisibleBasedOnUrl
  ) {
    return null
  }
  return <CtaTutoringBannerMobileOnly data={data} />
}
