const isArrayOfStrings = (arr: unknown): boolean =>
  Array.isArray(arr) && arr.every((item) => typeof item === 'string')

export const shouldRenderCTAByIncludedExcludedUrls = ({
  currentUrl,
  includedUrls = [],
  excludedUrls = [],
}: {
  currentUrl: string
  includedUrls: string[] | null
  excludedUrls: string[] | null
}): boolean => {
  try {
    if (
      !isArrayOfStrings(includedUrls) ||
      (excludedUrls !== null &&
        excludedUrls.length &&
        !isArrayOfStrings(excludedUrls))
    )
      return false

    const isExcluded = excludedUrls?.some((pattern) =>
      new RegExp(pattern.replace('*', '.*').replace(/^\/$/, '^/$')).test(
        currentUrl
      )
    )
    if (isExcluded) return false

    return (
      !!includedUrls?.length &&
      includedUrls.some((pattern) =>
        new RegExp(pattern.replace('*', '.*').replace(/^\/$/, '^/$')).test(
          currentUrl
        )
      )
    )
  } catch (error) {
    return false
  }
}
