import { Fragment, useEffect } from 'react'

import { StrapiCtaBannerList } from '@constants_folder/strapi_urls'

import { useCtaMediaBannersList } from '@hooks/CTA/useCtaMediaBannersList'
import useCtaDownloadAppQrPopup from '@hooks/dataFetching/CTA/useCtaDownloadAppQrPopup'

import CtaDownloadAppQrPopup from '@modules/CTA/CtaDownloadAppQrPopup/CtaDownloadAppQrPopup'
import { useCtaQueue } from '@modules/CTA/CtaQueueProvider/CtaQueueContext'
import CtaMediaBannersList from '@modules/CTA/MediaBanner/CtaMediaBannersList'

const CtaQueueManager = () => {
  const { currentCta, addCta, removeCta, setAsCurrentCta } = useCtaQueue()

  const {
    data,
    willRender: isMediaBannerWillRender,
    isOpen,
  } = useCtaMediaBannersList()
  const { shouldShowPopup: shouldShowDownloadAppQrCodePopup } =
    useCtaDownloadAppQrPopup()

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    const checkIfMediaBannerShouldRenderFirst = () => {
      if (timer) clearTimeout(timer)

      const bannerSlug = data?.attributes.slug || ''

      if (isMediaBannerWillRender) {
        addCta({
          id: `${bannerSlug}`,
          ctaComponent: <CtaMediaBannersList />,
        })
        return
      }

      if (shouldShowDownloadAppQrCodePopup) {
        timer = setTimeout(() => {
          setAsCurrentCta({
            id: StrapiCtaBannerList.DOWNLOAD_APP_QR_CODE,
            ctaComponent: <CtaDownloadAppQrPopup />,
          })
        }, 1500)
      }
    }

    checkIfMediaBannerShouldRenderFirst()

    return () => {
      clearTimeout(timer)
    }
  }, [
    addCta,
    data?.attributes.slug,
    isMediaBannerWillRender,
    isOpen,
    removeCta,
    setAsCurrentCta,
    shouldShowDownloadAppQrCodePopup,
  ])

  return <Fragment key={currentCta?.id}>{currentCta?.ctaComponent}</Fragment>
}
export default CtaQueueManager
