import classNames from 'classnames'
import { FC, useCallback, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

import { useClickOutside } from '@promova/utils/customHooks/customHooks'

import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import styles from './toast.module.scss'

interface Props {
  opened: boolean // Default toast state. If true - Toast opened
  variant?: 'long' | 'short' // Position of Toast elements. If short - Toast elements will be in one line, if long - action button will be under message // Default: 'short'.
  width?: number // custom toast container width // Default: 400px
  withoutNavBar?: boolean // if true Toast will be under NavBar
  message?: string // message in Toast (this prop replaceable by a children prop)
  actionTitle?: string // title of action button
  closeOnOverlayClick?: boolean // if true Toast will be closed on overlay click
  closeAfter?: number // time in ms after which Toast will be closed (Triggering onClose action)
  onAction?: () => void // callback which call when user click on action button
  onClose?: () => void // callback which call when user close Toast.
  className?: string // additional class name for Toast
  position?: 'bottomCenter' | 'bottomRight' // Toast positioning on the screen // Default: 'bottomCenter'.
  scaled?: boolean
  hideCloseButton?: boolean
}

export const CLOSE_AFTER_DEFAULT_DURATION = 5000

const Toast: FC<Props> = ({
  opened,
  variant = 'short',
  width,
  withoutNavBar,
  message,
  actionTitle,
  closeOnOverlayClick,
  closeAfter,
  onAction,
  onClose,
  hideCloseButton,
  className,
  children,
  position = 'bottomCenter',
  scaled = false,
}) => {
  const toastRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if (opened && closeAfter && onClose) {
      timer = setTimeout(() => {
        onClose()
      }, closeAfter)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [opened, closeAfter, onClose])

  const onOverlayClick = useCallback(() => {
    if (closeOnOverlayClick && onClose) {
      onClose()
    }
  }, [closeOnOverlayClick, onClose])

  useClickOutside(opened, toastRef, onOverlayClick)

  if (!opened || typeof window === 'undefined') return null

  const containerClassNames = classNames(
    styles.container,
    styles[`container_${variant}`],
    styles[`container_position_${position}`],
    {
      [styles.container_long_without_action]:
        variant === 'long' && !actionTitle,
      [styles.without_navbar]: withoutNavBar,
      [styles.scaled]: scaled,
    },
    className
  )

  const closeClassNames = classNames(styles.close_button, {
    [styles.close_button_short]: variant === 'short' && actionTitle,
  })

  return createPortal(
    <div className={containerClassNames} ref={toastRef} style={{ width }}>
      <div className={styles.content}>
        {children || <p className={styles.message}>{message}</p>}
      </div>

      {actionTitle && (
        <Button
          onClick={onAction}
          variant="text"
          size="s"
          className={styles.action_button}
        >
          {actionTitle}
        </Button>
      )}

      {onClose && !hideCloseButton && (
        <button type="button" className={closeClassNames} onClick={onClose}>
          <Icon
            icon="close"
            size={18}
            className={styles.close_icon}
            scaled={scaled}
          />
        </button>
      )}
    </div>,
    document.getElementById('toast-root') || document.body
  )
}

export default Toast
