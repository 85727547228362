import { FC, useCallback } from 'react'
import { useMediaQuery } from 'react-responsive'

import Modal from '@promova/ui/components/Modal/Modal'

import { useAppSelector } from '@reduxStore/hooks'

import { CtaPopupAllPagesAttributes } from '@_types/strapi/singleTypes'

import { sendAnalytics } from '@utils/analytics'
import useLandingInfo from '@utils/customHooks/useLandingInfo'
import { ColorDataTypes } from '@utils/strapiCommons/types'
import { getColor } from '@utils/strapiCommons/utils'

import TimerBlock from '@templates/Landing/layoutV2/TimerBlock/TimerBlock'

import Button from '@elements/ButtonV2/Button'
import StrapiImage from '@elements/StrapiImage/StrapiImage'

import styles from './cta_modal.module.scss'

type Props = {
  popup: CtaPopupAllPagesAttributes
  isModalOpen: boolean
  handleClose: () => void
}

const defaultTimerBgColor: ColorDataTypes = {
  name: 'Purple',
  opacity: 100,
}
const defaultMainSectionBgColor: ColorDataTypes = {
  name: 'Purple',
  opacity: 50,
}

const CtaModal: FC<Props> = (props) => {
  const { popup, isModalOpen, handleClose } = props

  const timer = popup?.timer

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  })

  const mainSectionBgColor = getColor(
    popup?.bgColor || defaultMainSectionBgColor
  )

  const amplitudeInited = useAppSelector(
    (state) => state?.amplitude?.amplitudeInited
  )
  const { LANDING_PATH } = useLandingInfo()

  const handleSendOnbEvent = useCallback(() => {
    if (!amplitudeInited) return

    sendAnalytics({
      eventName: 'landing_clicked_cta',
      data: {
        landing_name: 'none',
        page_path: LANDING_PATH,
        place: 'popup',
        text: popup?.buttonEventText,
        type: popup?.buttonEventType,
      },
    })
  }, [
    amplitudeInited,
    LANDING_PATH,
    popup?.buttonEventText,
    popup?.buttonEventType,
  ])

  if (!popup) return null

  return (
    <Modal
      opened={isModalOpen}
      withCloseButton
      onClose={handleClose}
      modalClassName={styles.modal_wrapper}
    >
      <div className={styles.timer_block_wrapper}>
        <TimerBlock
          disabled={timer?.isDisabled}
          dateStart={timer?.dateStart}
          dateEnd={timer?.dateEnd}
          subtitle={isMobile ? '' : timer?.timerText}
          color={popup?.timer?.timerBgColor || defaultTimerBgColor}
        />
      </div>

      <div
        className={styles.main_block}
        style={{ backgroundColor: mainSectionBgColor }}
      >
        <div className={styles.left_block}>
          <div className={styles.title}>{popup?.title}</div>
          <div className={styles.text}>{popup?.text}</div>
          <Button
            className={styles.btn_main}
            type="filled"
            link={popup?.button?.href}
            onClick={handleSendOnbEvent}
            isExternal={popup?.button?.isExternal ?? true}
          >
            {popup?.button?.label}
          </Button>
        </div>

        <div className={styles.right_block}>
          <figure className={styles.image_container}>
            <StrapiImage image={popup?.image} layout="fill" />
          </figure>
        </div>
      </div>
    </Modal>
  )
}

export default CtaModal
