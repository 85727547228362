import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useContext, useMemo } from 'react'

import sortByOrder from '@promova/utils/sortByOrder'

import {
  BALTIC_COUNTRIES,
  EMPTY_LINK,
  EU_COUNTRIES,
  GB_COUNTRY,
} from '@constants'

import { LinkType } from '@_types/landings'

import { sendAnalytics } from '@utils/analytics'
import { useQueryCountry } from '@utils/useQueryCountry'

import { AuthContext } from '@modules/Auth/AuthProvider'

import StrapiImage from '@elements/StrapiImage/StrapiImage'

import styles from './links_list.module.scss'

type Props = {
  links: LinkType[]
  customLinkClassName?: string
  cookieLinkTitle?: string
}

type ItemProps = {
  link: LinkType
  customLinkClassName?: string
}

const LinkItem: FC<ItemProps> = ({ link, customLinkClassName }) => {
  const linkClassNames = classNames(styles.link_item, {
    [styles.link_with_image]: !!link?.icon?.data,
    [customLinkClassName as string]: !!customLinkClassName,
  })
  const { locale } = useRouter()

  const handleLinkClick = () => {
    if (link.href === '/contact-us' || link.href === `/${locale}/contact-us`) {
      sendAnalytics({
        eventName: 'gen_clicked_contact_us',
        data: {
          place: 'footer',
        },
      })
    }
  }

  return (
    <li className={linkClassNames}>
      <Link href={link.href || EMPTY_LINK} passHref>
        {/* eslint-disable-next-line */}
        <a
          onClick={handleLinkClick}
          className={styles.nav_link}
          target={link.target || '_self'}
          rel={link.rel || ''}
        >
          {link?.icon?.data && (
            <figure className={styles.link_image}>
              <StrapiImage image={link.icon} />
            </figure>
          )}
          {link.label}
        </a>
      </Link>
    </li>
  )
}

const LinksList: FC<Props> = ({
  links,
  customLinkClassName,
  cookieLinkTitle,
}) => {
  const { user } = useContext(AuthContext)
  const { countryCode, region } = useQueryCountry()

  const isCalifornia = region === 'California'
  const isEU = [...EU_COUNTRIES, ...BALTIC_COUNTRIES, GB_COUNTRY].includes(
    countryCode
  )
  const sortedLinks = useMemo(() => sortByOrder(links), [links])

  const handleShowCookieSettings = () => {
    if (window && typeof (window as any).revisitCkyConsent === 'function') {
      ;(window as any).revisitCkyConsent()
    }
  }

  return (
    <ul>
      {sortedLinks
        .filter((link) => !link.visible)
        .map((link) => (
          <LinkItem
            key={link.id}
            link={link}
            customLinkClassName={customLinkClassName}
          />
        ))}
      {!user &&
        sortedLinks
          .filter((link) => link.visible === 'no_auth')
          .map((link) => (
            <LinkItem
              key={link.id}
              link={link}
              customLinkClassName={customLinkClassName}
            />
          ))}
      {isEU && (
        <>
          {sortedLinks
            .filter((link) => link.visible === 'eu')
            .map((link) => (
              <LinkItem
                key={link.id}
                link={link}
                customLinkClassName={customLinkClassName}
              />
            ))}
          {cookieLinkTitle && (
            <li className={customLinkClassName}>
              {/* eslint-disable-next-line */}
              <a
                href="#"
                className={styles.nav_link}
                onClick={handleShowCookieSettings}
              >
                {cookieLinkTitle}
              </a>
            </li>
          )}
        </>
      )}
      {isCalifornia &&
        sortedLinks
          .filter((link) => link.visible === 'california')
          .map((link) => (
            <LinkItem
              key={link.id}
              link={link}
              customLinkClassName={customLinkClassName}
            />
          ))}
    </ul>
  )
}

export default LinksList
