/* eslint-disable no-use-before-define */

type StrapiSingleEntityResponse<T> = {
  id: number
  attributes: {
    createdAt: string
    updatedAt: string
    publishedAt: string
    localizations: Localizations
  } & T // T is for the rest of attributes
}

export interface StrapiBaseResponse<T> {
  data: StrapiSingleEntityResponse<T>
  error: boolean
  meta: Meta
}

export interface StrapiMultipleBaseResponse<T> {
  data: StrapiSingleEntityResponse<T>[]
  error: boolean
  meta: Meta
}

export interface StrapiBaseCollectionTypeResponse<T> {
  data: {
    id: number
    attributes: {
      createdAt?: string
      updatedAt?: string
      publishedAt?: string
      localizations?: Localizations
    } & T // T is for the rest of attributes
  }[]
  error: boolean
  meta: Meta
}

// TODO: update to proper type here
export interface Localizations {
  data: unknown[]
}

// TODO: update to proper type here
export type Meta = Record<string, unknown>

export interface Image {
  data: {
    id: number
    attributes: ImageAttributes
  }
}

interface BaseMediaAttributes {
  name: string
  hash: string
  ext: string
  mime: string
  size: number
  url: string
  provider_metadata: any
  createdAt: string
  updatedAt: string
  provider: string
}

export interface ImageAttributes extends BaseMediaAttributes {
  alternativeText: string
  caption: null // TODO: update to proper type here
  width: number
  height: number
  formats: ImageFormat
  previewUrl: string
}

export interface VideoAttributes extends BaseMediaAttributes {
  alternativeText?: string
  caption?: string
  width?: number
  height?: number
  formats: null
  previewUrl?: string
}

export interface ImageFormat {
  large: ImageSize
  small: ImageSize
  medium: ImageSize
  thumbnail: ImageSize
}

export interface ImageSize {
  ext: string
  url: string
  hash: string
  mime: string
  name: string
  path: null
  size: number
  width: number
  height: number
}

export enum ApprovalStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}
