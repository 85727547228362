import {
  CTA_BANNER_LIST,
  DEFAULT_LOCALE,
  StrapiCtaBannerList,
} from '@constants'

import { StrapiBaseCollectionTypeResponse } from '@_types/strapi/common'
import { BannerRelationResponse } from '@_types/strapi/singleTypes'

import { CTA_REFETCH_ATTEMPTS, HOURS_24 } from '@hooks/dataFetching/constants'
import { useQueryFactory } from '@hooks/dataFetching/useQueryFactory'
import { fetchStrapiViaProxy } from '@hooks/dataFetching/utils'

interface Props {
  slug: (typeof StrapiCtaBannerList)[keyof typeof StrapiCtaBannerList]
  locale?: string | undefined
  populate?: Record<string, any> | string
  filters?: Record<string, any>
  fields?: string | string[]
  enabled?: boolean
}

const ctaPopulateTemplate = {
  banner: {
    populate: [
      'button.icon',
      'link.icon',
      'image',
      'timer.timerBgColor',
      'bgColor',
      'ctaButtonLink.icon',
      'stepsList',
      'displayOn',
      'color',
      'startImage',
      'finishButton.icon',
      'backgroundColor',
    ],
  },
}

export const useCtaBannerListQuery = <T>({
  slug,
  locale = DEFAULT_LOCALE,
  populate,
  filters = {},
  fields,
  enabled = true,
}: Props) => {
  const localizedCtaSlug =
    locale === DEFAULT_LOCALE ? slug : `${slug}-${locale}`

  return useQueryFactory(
    [CTA_BANNER_LIST, slug, locale],
    (): Promise<StrapiBaseCollectionTypeResponse<BannerRelationResponse<T>>> =>
      fetchStrapiViaProxy({
        url: CTA_BANNER_LIST,
        urlParamsObject: {
          populate: populate || ctaPopulateTemplate,
          locale,
          filters: {
            ...filters,
            slug: {
              $eq: localizedCtaSlug,
            },
          },
          fields,
        },
      })
  )({
    enabled,
    cacheTime: HOURS_24,
    staleTime: HOURS_24,
    retry: CTA_REFETCH_ATTEMPTS,
    select: (data) => data?.data[0]?.attributes?.banner[0],
  })
}
