import { useEffect, useRef, useState } from 'react'

interface UseGlobalScroll {
  isScrolled: boolean
  resetScroll: () => void
}

const useGlobalScroll = (threshold: number = 0): UseGlobalScroll => {
  const [isScrolled, setIsScrolled] = useState(false)
  const isMountedRef = useRef(true)

  const resetScroll = () => {
    if (isMountedRef.current) {
      setIsScrolled(false)
    }
  }

  useEffect(() => {
    isMountedRef.current = true

    const handler = () => {
      if (window.scrollY > threshold && !isScrolled && isMountedRef.current) {
        setIsScrolled(true)
        document.removeEventListener('scroll', handler)
      }
    }

    document.addEventListener('scroll', handler)

    if (isMountedRef.current && isScrolled) {
      document.removeEventListener('scroll', handler)
    }

    return () => {
      isMountedRef.current = false
      document.removeEventListener('scroll', handler)
    }
  }, [isScrolled, threshold])

  return { isScrolled, resetScroll }
}

export default useGlobalScroll
