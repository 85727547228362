const sortByOrder = <T extends Record<'order', any>>(array: T[]): T[] => {
  const isArray = Array.isArray(array)

  if (!isArray) {
    return []
  }

  const resArray = [...array]
  resArray.sort((a, b) => a.order - b.order)

  return resArray
}

export default sortByOrder
