import { FC, ReactNode } from 'react'

interface Props {
  children?: ReactNode
  className?: string
  text?: string
}
const ErrorStateText: FC<Props> = ({ children, className, text }) => (
  <div className={className}>
    {text}
    {children}
  </div>
)

export default ErrorStateText
