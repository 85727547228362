import classNames from 'classnames'
import { ChangeEvent, FC } from 'react'

import Icon from '../../components/Icon/Icon'
import styles from './checkbox.module.scss'

interface Props {
  label?: string
  checked: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  isError?: boolean
  className?: string

  [key: string]: any
}

const Checkbox: FC<Props> = ({
  label,
  checked,
  onChange,
  children,
  isError,
  className,
}) => {
  const labelClassnames = classNames(styles.checkbox_container, {
    [styles.checkbox_container_error]: isError,
  })
  const checkboxClassNames = classNames(styles.checkmark_bg, className)

  return (
    <label className={labelClassnames}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        readOnly={!onChange}
      />
      <div className={checkboxClassNames}>
        <Icon icon="check" size={18} className={styles.checkmark_icon} />
      </div>
      {label ? <p className={styles.label}>{label}</p> : children}
    </label>
  )
}
export default Checkbox
