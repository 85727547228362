import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { useEffect, useState } from 'react'

import Toast from '@promova/ui/components/Toast/Toast'

import { THIRTY_DAYS_IN_MILLISECONDS } from '@constants_folder/common'

import { ColorDataTypes } from '@utils/strapiCommons/types'
import { getColor } from '@utils/strapiCommons/utils'

import ExclusiveOfferScreen from '@modules/CTA/CtaEmailSubscribe/ExclusiveOfferScreen/ExclusiveOfferScreen'
import SuccessScreen from '@modules/CTA/CtaEmailSubscribe/SuccessScreen/SuccessScreen'
import TimerBlock from '@modules/CTA/CtaEmailSubscribe/TimerBlock/TimerBlock'
import TimesUpScreen from '@modules/CTA/CtaEmailSubscribe/TimesUpScreen/TimesUpScreen'
import { EmailSubscribeFormValues } from '@modules/CTA/CtaEmailSubscribe/types/CTAEmailSubscribeListTypes'
import useCtaEmailSubscribe from '@modules/CTA/CtaEmailSubscribe/useCtaEmailSubscribe'
import { setClosedStatusForBanner } from '@modules/CTA/CtaEmailSubscribe/utils'
import PopUp from '@modules/PopUp/PopUp'

import styles from './cta_email_subscribe.module.scss'

enum POPUP_STEPS {
  OFFER = 'offer',
  TIMES_UP = 'time-is-up',
  SUCCESS = 'success',
}
const DEFAULT_COLOR: ColorDataTypes = {
  name: 'Purple',
  opacity: 100,
}
const ERROR_MESSAGE_CLOSE_DELAY = 5000
const SUCCESS_SCREEN_AUTOMATIC_CLOSE_DELAY = 10000
const SUBMIT_ERROR_MESSAGE = t`Oops! Something went wrong. Please refresh the page and try again.`

const CtaEmailSubscribe = () => {
  const [step, setStep] = useState(POPUP_STEPS.OFFER)
  const [isTimerPaused, setIsTimerPaused] = useState(false)

  const {
    isOpen,
    timerStartCount,
    initialTimerSecondsFromCms,
    isSubmitError,
    resetSubmitError,
    closeBanner,
    onSubscribe,
    data,
  } = useCtaEmailSubscribe()

  const banner = data?.banner[0]
  const bannerSlug = data?.slug

  const handelFormSubmit = async (values: EmailSubscribeFormValues) => {
    setIsTimerPaused(true)
    const res = await onSubscribe(values)
    if (res?.message === 'OK') {
      setStep(POPUP_STEPS.SUCCESS)
      setClosedStatusForBanner(bannerSlug, THIRTY_DAYS_IN_MILLISECONDS)
    }
  }

  const handleClosePopup = () => {
    closeBanner(bannerSlug)
  }

  const handleTimesUp = () => {
    setStep(POPUP_STEPS.TIMES_UP)
  }

  useEffect(() => {
    if (initialTimerSecondsFromCms === 0 || !isOpen) {
      setStep(POPUP_STEPS.OFFER)
    }
  }, [initialTimerSecondsFromCms, isOpen])

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if (isOpen && bannerSlug && step === POPUP_STEPS.SUCCESS) {
      timer = setTimeout(() => {
        closeBanner(bannerSlug)
      }, SUCCESS_SCREEN_AUTOMATIC_CLOSE_DELAY)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [bannerSlug, closeBanner, isOpen, step])

  const timerBgColor = getColor(banner?.timerBgColor || DEFAULT_COLOR)
  const containerBgColor = getColor(banner?.mainBlockBgColor || DEFAULT_COLOR)

  if (!banner || !isOpen || initialTimerSecondsFromCms === null) return null

  const shouldShowTimerBlock =
    step === POPUP_STEPS.OFFER &&
    initialTimerSecondsFromCms !== 0 &&
    timerStartCount !== null

  return (
    <>
      <PopUp
        handleClose={handleClosePopup}
        closeByClickOutside={false}
        withOverlay={false}
        popoverClassName={styles.popup_wrapper}
        preventScroll={false}
      >
        {shouldShowTimerBlock && (
          <TimerBlock
            text={banner?.timerText}
            bannerSlug={bannerSlug}
            startCount={timerStartCount}
            bannerTimerSeconds={initialTimerSecondsFromCms}
            isPaused={isTimerPaused}
            backgroundColor={timerBgColor}
            onTimesUp={handleTimesUp}
          />
        )}
        <div
          className={styles.container}
          style={{ backgroundColor: containerBgColor }}
        >
          {step === POPUP_STEPS.OFFER && (
            <ExclusiveOfferScreen
              onFormSubmit={handelFormSubmit}
              emailLabel={banner?.emailLabel}
              buttonText={banner?.button?.label}
              bannerTitle={banner?.title}
              strapiImage={banner?.media}
            />
          )}
          {step === POPUP_STEPS.TIMES_UP && (
            <TimesUpScreen
              onFormSubmit={handelFormSubmit}
              emailLabel={banner?.emailLabel}
              buttonText={banner?.button.label}
              bannerTitle={banner?.secondScreenTitle}
              strapiImage={banner?.secondScreenMedia}
            />
          )}
          {step === POPUP_STEPS.SUCCESS && <SuccessScreen />}
        </div>
      </PopUp>
      <Toast
        opened={isSubmitError}
        closeAfter={ERROR_MESSAGE_CLOSE_DELAY}
        message={i18n._(SUBMIT_ERROR_MESSAGE)}
        onClose={resetSubmitError}
        position="bottomRight"
      />
    </>
  )
}

export default CtaEmailSubscribe
