import classNames from 'classnames'
import { FC, MouseEvent, useEffect, useRef } from 'react'

import Button from '@promova/ui/components/Button/Button'
import { zeroFormattedNumber } from '@promova/utils/helpers'
import secondsToTime from '@promova/utils/secondsToTime'

import useVideo from '@hooks/useVideo'

import styles from './video_player.module.scss'

interface Props {
  src: string
  mimeType: string
  isStopped?: boolean
  onFullScreenMode?: () => void
  isFullScreen?: boolean
  preventFullscreenToggle?: boolean
  autoPlay?: boolean
  loop?: boolean
  mutedOnStart?: boolean
  onReadyToPlay?: () => void
  scaled?: boolean
  containerClassName?: string
}

/* About readyState here: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState */
export enum VideoReadyState {
  'HAVE_NOTHING',
  'HAVE_METADATA',
  'HAVE_CURRENT_DATA',
  'HAVE_FUTURE_DATA',
  'HAVE_ENOUGH_DATA',
}

const VideoPlayer: FC<Props> = ({
  src,
  mimeType,
  isStopped,
  onFullScreenMode,
  isFullScreen = false,
  preventFullscreenToggle,
  autoPlay = true,
  loop = true,
  mutedOnStart = true,
  onReadyToPlay,
  scaled = true,
  containerClassName,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const {
    onPlay: onPlayVideo,
    onStop: onStopVideo,
    playing,
    position,
    duration,
    muted,
    onMute,
    onChangePosition,
    toggleFullscreen,
  } = useVideo({ videoRef })

  useEffect(() => {
    if (
      (videoRef.current?.readyState || 0) >= VideoReadyState.HAVE_CURRENT_DATA
    ) {
      onReadyToPlay?.()
    }
  }, [onReadyToPlay, videoRef.current?.readyState])

  useEffect(() => {
    if (!mutedOnStart) {
      onMute()
    }
  }, [mutedOnStart, onMute])

  useEffect(() => {
    if (isStopped) {
      onStopVideo()
    } else if (autoPlay) {
      onPlayVideo()
    }
  }, [autoPlay, isStopped, onPlayVideo, onStopVideo])

  useEffect(
    () => () => {
      onStopVideo()
    },
    [onStopVideo]
  )

  const handleVolumeClick = () => {
    onMute()
  }

  const togglePlayPauseState = () => {
    if (playing) {
      onStopVideo()
    } else {
      onPlayVideo()
    }
  }

  const handleProgressBarClick = (e: MouseEvent<HTMLDivElement>) => {
    const { left, width } = e.currentTarget.getBoundingClientRect()
    const clickPosition = e.clientX - left
    const progressTime = clickPosition / width
    const newCurrentTime = (duration as number) * progressTime
    onChangePosition(newCurrentTime)
  }

  const handleFullscreen = () => {
    if (!preventFullscreenToggle) {
      toggleFullscreen()
    }
    onFullScreenMode?.()
  }

  const progress = ((position as number) / (duration as number)) * 100 || 0
  const formattedCurrentTime = secondsToTime(position || 0)
  const formattedDuration = secondsToTime(duration || 0)

  const videoContainerClassNames = classNames(
    styles.video_container,
    containerClassName
  )
  const progressBarClassNames = classNames(styles.progress_bar, {
    [styles.scaled]: scaled,
  })
  const timeClassNames = classNames(styles.time, {
    [styles.scaled]: scaled,
  })
  const videoControlsClassNames = classNames(styles.video_controls, {
    [styles.scaled]: scaled,
  })

  if (!src) return null

  return (
    <div className={videoContainerClassNames}>
      <div className={videoControlsClassNames}>
        <Button
          icon={playing ? 'pause' : 'play'}
          onClick={togglePlayPauseState}
          className={styles.button}
          withScaling={scaled}
        />
        <p className={timeClassNames}>
          {formattedCurrentTime.minutes}:
          {zeroFormattedNumber(formattedCurrentTime.seconds)} /{' '}
          {formattedDuration.minutes}:
          {zeroFormattedNumber(formattedDuration.seconds)}
        </p>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          role="progressbar"
          aria-label="Progress bar"
          tabIndex={-1}
          className={progressBarClassNames}
          onClick={handleProgressBarClick}
        >
          <div
            className={styles.progress_bar_inner}
            style={{ width: `${progress}%` }}
          />
        </div>
        <Button
          icon={muted ? 'volume_mute' : 'volume'}
          onClick={handleVolumeClick}
          className={styles.button}
          withScaling={scaled}
        />
        <Button
          icon={isFullScreen ? 'fullscreen_out' : 'fullscreen_in'}
          onClick={handleFullscreen}
          className={styles.button}
          withScaling={scaled}
        />
      </div>
      <video
        className={styles.video}
        autoPlay={autoPlay}
        loop={loop}
        ref={videoRef}
        muted={muted}
      >
        <source src={src} type={mimeType} />
        <track default kind="captions" srcLang="en" src="#" />
      </video>
    </div>
  )
}

export default VideoPlayer
