import { Trans } from '@lingui/macro'

import { useAppSelector } from '@reduxStore/hooks'

import { sendAnalytics } from '@utils/analytics'
import useLandingInfo from '@utils/customHooks/useLandingInfo'

import StoreButton from '@elements/StoreButton/StoreButton'

import styles from './cta_section.module.scss'

const CtaSection = () => {
  const amplitudeInited = useAppSelector(
    (state) => state?.amplitude?.amplitudeInited
  )
  const { LANDING_PATH } = useLandingInfo()

  const handleSendOnbEvent = () => {
    if (!amplitudeInited) return

    sendAnalytics({
      eventName: 'landing_clicked_cta',
      data: {
        landing_name: 'none',
        page_path: LANDING_PATH,
        place: 'header',
        text: 'Get The App',
        type: 'download',
      },
    })
  }

  return (
    <div className={styles.cta_section}>
      <div className={styles.title}>
        <Trans>Get the App:</Trans>
      </div>
      <div className={styles.stores}>
        <StoreButton
          type="apple"
          theme="circle"
          onClick={handleSendOnbEvent}
        />
        <StoreButton
          type="google"
          theme="circle"
          onClick={handleSendOnbEvent}
        />
        <StoreButton
          type="huawei"
          theme="circle"
          onClick={handleSendOnbEvent}
        />
      </div>
    </div>
  )
}

export default CtaSection
