import footerEnData from '../_config/translates/footer/footer-en.json'
import footerEsData from '../_config/translates/footer/footer-es.json'
import footerPtData from '../_config/translates/footer/footer-pt.json'
import footerUkData from '../_config/translates/footer/footer-uk.json'

const footerData: {
  [key: string]: any
} = {
  es: footerEsData,
  pt: footerPtData,
  uk: footerUkData,
  en: footerEnData,
}

const getFooterDataByLocale = (locale?: string) =>
  footerData[locale as string] || footerData.en

export default getFooterDataByLocale
