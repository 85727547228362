import { Trans } from '@lingui/macro'
import React, { FC, useMemo } from 'react'

import Icon from '@promova/ui/components/Icon/Icon'

import styles from './download_app_button.module.scss'

interface Props {
  onClick: () => void
  content?: JSX.Element | string
}

const DownloadAppButton: FC<Props> = ({
  content = <Trans>Get the App</Trans>,
  onClick,
}) => {
  // Prevent icon from blinking on scroll
  const memoizedMobileIcon = useMemo(() => <Icon icon="mobile" />, [])
  return (
    <button type="button" className={styles.get_app_btn} onClick={onClick}>
      {memoizedMobileIcon}
      {content}
    </button>
  )
}

export default DownloadAppButton
