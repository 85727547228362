import classNames from 'classnames'
import { FC } from 'react'

import EmailSubscribeForm from '@modules/CTA/CtaEmailSubscribe/EmailSubscribeForm/EmailSubscribeForm'
import styles from '@modules/CTA/CtaEmailSubscribe/cta_email_subscribe.module.scss'
import { EmailSubscribeFormValues } from '@modules/CTA/CtaEmailSubscribe/types/CTAEmailSubscribeListTypes'

import StrapiImage from '@elements/StrapiImage/StrapiImage'

interface Props {
  bannerTitle?: string
  onFormSubmit: (values: EmailSubscribeFormValues) => Promise<void>
  emailLabel?: string
  buttonText?: string
  strapiImage?: any
}

const ExclusiveOfferScreen: FC<Props> = ({
  onFormSubmit,
  buttonText,
  emailLabel,
  strapiImage,
  bannerTitle,
}) => {
  const titleClassNames = classNames(styles.title, {
    [styles.image_margin]: Boolean(strapiImage?.data),
  })

  return (
    <>
      <div className={titleClassNames}>
        {bannerTitle}
        <figure className={styles.image_wrapper}>
          <StrapiImage image={strapiImage} layout="fill" />
        </figure>
      </div>
      <EmailSubscribeForm
        emailLabel={emailLabel}
        buttonText={buttonText}
        onFormSubmit={onFormSubmit}
      />
    </>
  )
}

export default ExclusiveOfferScreen
