import Link from 'next/link'
import React, { FC } from 'react'

import { EMPTY_LINK, Event } from '@constants'

import { CtaWithRunningTextType } from '@_types/strapi/singleTypes'

import useLandingInfo from '@hooks/useLandingInfo'

import { sendAnalytics } from '@utils/analytics'
import { ColorDataTypes } from '@utils/strapiCommons/types'
import { getColor } from '@utils/strapiCommons/utils'

import RunningText from '@elements/Ticker/RunningText'

import styles from './CtaWithTicker.module.scss'

type Props = {
  data: CtaWithRunningTextType
}

const defaultBgColor: ColorDataTypes = {
  name: 'Negative',
  opacity: 100,
}

const CtaWithRunningText: FC<Props> = ({ data }) => {
  const { LANDING_PATH } = useLandingInfo()

  const handleSendClickCTAAnalytics = () => {
    sendAnalytics({
      eventName: Event.GEN_CLICKED_CTA,
      data: {
        page_path: LANDING_PATH,
        place: 'banner',
        type: 'pricing page',
        redirect_to: data?.link?.href,
        text: `${data?.leftText} ${data?.mainText} ${data?.rightText}`,
      },
    })
  }

  const bgColorData = data?.backgroundColor || defaultBgColor
  const bgColor = getColor(bgColorData) as string

  return (
    <Link
      href={data?.link?.href || EMPTY_LINK}
      rel={data?.link?.rel as string}
      target={data?.link?.target as string}
      onClick={handleSendClickCTAAnalytics}
      legacyBehavior={false}
    >
      <div className={styles.cta} style={{ backgroundColor: bgColor }}>
        <RunningText tickerText={data?.runningText} />
        <div className={styles.title_wrap}>
          <p className={styles.title}>{data?.leftText}</p>
          <p className={styles.title_main}>{data?.mainText}</p>
          <p className={styles.title_right}>{data?.rightText}</p>
        </div>
      </div>
    </Link>
  )
}

export default CtaWithRunningText
