import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { FC } from 'react'

import styles from './input.module.scss'

interface Props {
  name: string
  value: string | number | undefined
  onChange: (...args: any) => void
  label?: string
  type?: string
  error?: string | null | undefined | Record<string, any>
  withErrorExtended?: boolean
  placeholder?: string
  className?: string
  labelClassName?: string
  errorBoxClassName?: string
  disabled?: boolean
  [key: string]: any
}

const Input: FC<Props> = (props: Props) => {
  const {
    name,
    value,
    onChange,
    label,
    type = 'text',
    error,
    withErrorExtended = false,
    placeholder,
    className,
    labelClassName,
    errorBoxClassName,
    disabled,
    ...rest
  } = props

  const inputStyles = classNames(styles.input, {
    [styles.with_error]: !!error,
    [styles.with_error_extended]: !!error && withErrorExtended,
    [styles.with_no_error_extended]: !error && value,
    [styles.disabled]: disabled,
  })

  const wrapperStyles = classNames(styles.input_wrapper, {
    [className as string]: !!className,
  })

  const labelStyles = classNames(styles.label, {
    [labelClassName as string]: !!labelClassName,
  })

  const errorBoxStyles = classNames(styles.error_box, errorBoxClassName)

  return (
    <label htmlFor={name} className={wrapperStyles}>
      <div className={labelStyles}>{label && <Trans id={label} />}</div>
      <input
        type={type}
        name={name}
        className={inputStyles}
        value={value}
        onChange={onChange}
        placeholder={placeholder || ''}
        disabled={disabled}
        {...rest}
      />
      <div className={errorBoxStyles}>{error}</div>
    </label>
  )
}

export default Input
