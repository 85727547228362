import { FC } from 'react'

import Modal from '@promova/ui/components/Modal/Modal'

import { useAppSelector } from '@reduxStore/hooks'

import { sendAnalytics } from '@utils/analytics'
import useLandingInfo from '@utils/customHooks/useLandingInfo'

import DownloadScreen from '@modules/Modal/DownloadAppModal/DownloadScreen/DownloadScreen'

import styles from './download_app_modal.module.scss'

type Props = {
  isOpen: boolean
  onClose: () => void
  qrCodeLink?: string
}

const DownloadAppModal: FC<Props> = (props) => {
  const { isOpen, onClose, qrCodeLink } = props

  const amplitudeInited = useAppSelector(
    (state) => state?.amplitude?.amplitudeInited
  )
  const { LANDING_PATH } = useLandingInfo()

  const handleSendOnbEvent = () => {
    if (!amplitudeInited) return

    sendAnalytics({
      eventName: 'landing_clicked_cta',
      data: {
        landing_name: 'none',
        page_path: LANDING_PATH,
        place: 'header',
        text: 'Get The App',
        type: 'download',
      },
    })
  }

  return (
    <Modal opened={isOpen} onClose={onClose} withCloseButton>
      <div className={styles.container}>
        <DownloadScreen
          qrCodeSize={200}
          onLinkClick={handleSendOnbEvent}
          qrCodeLink={qrCodeLink}
        />
      </div>
    </Modal>
  )
}

export default DownloadAppModal
