import React, { FC } from 'react'

import SideNavigation, {
  SideNavigationProps,
} from '@templates/LandingNavigation/SideNavigation/SideNavigation'

import styles from './side_navigation_wrapper.module.scss'

interface Props extends SideNavigationProps {
  onClick: () => void
}

const SideNavigationWrapper: FC<Props> = ({ onClick, ...props }) => (
  <div className={styles.mobile_container}>
    <button
      type="button"
      onClick={onClick}
      aria-label="side navigation"
      className={styles.burger}
    >
      <span className={styles.burger_icon} />
    </button>

    <SideNavigation {...props} />
  </div>
)

export default SideNavigationWrapper
