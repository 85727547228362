import useCtaDownloadAppQrPopup from '@hooks/dataFetching/CTA/useCtaDownloadAppQrPopup'

import StrapiImage from '@elements/StrapiImage/StrapiImage'

import styles from './cta_download_app_qr_popup.module.scss'

const CtaDownloadAppQrPopup = () => {
  const { data, shouldShowPopup } = useCtaDownloadAppQrPopup()

  if (!shouldShowPopup) {
    return null
  }
  const DEFAULT_COLOR = '#dfe3ff'
  const textContainerColor = data?.backgroundColor || DEFAULT_COLOR

  return (
    <div className={styles.wrapper}>
      <div className={styles.image_wrapper}>
        <figure className={styles.image}>
          <StrapiImage image={data?.image} />
        </figure>
      </div>
      <div
        className={styles.text_container}
        style={{ backgroundColor: textContainerColor }}
      >
        <div className={styles.text}>{data?.title}</div>
      </div>
    </div>
  )
}

export default CtaDownloadAppQrPopup
