import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { HOME_PATH, StrapiCtaBannerList } from '@constants'

import { CtaAppBannerAttributes } from '@_types/strapi/singleTypes'

import { useCtaBannerListQuery } from '@hooks/dataFetching/CTA/useCtaBannerListQuery'
import useBreakpoints from '@hooks/useBreakpoints'

export const useCtaAppBannerOpenLogic = () => {
  const { xs: isMobile } = useBreakpoints()
  const { pathname, locale } = useRouter()
  const isHomePath = pathname === HOME_PATH

  const {
    data: ctaAppBanner,
    isError: isErrorCtaAppBanner,
    isLoading: isLoadingCtaAppBanner,
  } = useCtaBannerListQuery<CtaAppBannerAttributes>({
    locale,
    enabled: !isHomePath,
    slug: StrapiCtaBannerList.APP_BANNER,
  })

  return useMemo(
    () =>
      !isMobile &&
      !isHomePath &&
      !isLoadingCtaAppBanner &&
      !isErrorCtaAppBanner &&
      ctaAppBanner &&
      !ctaAppBanner?.isDisabled,
    [
      ctaAppBanner,
      isMobile,
      isHomePath,
      isLoadingCtaAppBanner,
      isErrorCtaAppBanner,
    ]
  )
}
