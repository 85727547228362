import { AxiosResponse } from 'axios'

import axiosInstance from '@api/axiosInstanse'

const API_HOST = process.env.NEXT_PUBLIC_API_HOST

export const subscribeToNewsletter = async (
  body: Record<string, any>
): Promise<AxiosResponse<{ message: string }>> =>
  axiosInstance.post(`${API_HOST}/v1/subscribe`, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
