import { FC } from 'react'

import Modal from '@promova/ui/components/Modal/Modal'

import { CtaDontGoPopupType } from '@_types/strapi/singleTypes'

import Button from '@elements/ButtonV2/Button'
import StrapiImage from '@elements/StrapiImage/StrapiImage'

import styles from './CTAPopupClosePage.module.scss'

type Props = {
  data: CtaDontGoPopupType
  isModalOpen: boolean
  handleClose: () => void
  handleCtaClick: () => void
}

const CTAPopupDontGo: FC<Props> = ({
  data,
  isModalOpen,
  handleClose,
  handleCtaClick,
}) => (
  <Modal
    opened={isModalOpen}
    withCloseButton
    onClose={handleClose}
    modalClassName={styles.modal_wrapper}
  >
    <div
      className={styles.main_block}
      style={{ backgroundColor: data?.bgColor }}
    >
      <div className={styles.left_block}>
        <div className={styles.title}>{data?.title}</div>
        <div className={styles.text}>{data?.text}</div>
        {data?.button?.text && data?.button?.href && (
          <Button
            className={styles.btn_main}
            type="filled"
            {...data.button}
            link={data.button.href}
            onClick={handleCtaClick}
          >
            {data.button.text}
          </Button>
        )}
      </div>

      <div className={styles.right_block}>
        <figure className={styles.image_container}>
          {data?.image?.data && (
            <StrapiImage image={data?.image} layout="fill" />
          )}
        </figure>
      </div>
    </div>
  </Modal>
)

export default CTAPopupDontGo
