type TypeMapKeys = 'video' | 'audio' | 'image'

const typesMap: Record<TypeMapKeys, string[]> = {
  video: ['mp4', 'avi', 'mpeg', 'webm'],
  audio: ['mpeg', 'aac', 'ogg', 'webm', 'mp3', 'x-m4a', 'mpeg'],
  image: ['jpeg', 'jpg', 'png', 'gif', 'webp', 'svg'],
}

export const getFileTypeFromMime = (
  mimeType?: string | null
): TypeMapKeys | 'unknown' => {
  if (!mimeType) return 'unknown'

  const mimeParts = mimeType.split('/') as [TypeMapKeys, string]
  if (mimeParts.length !== 2) {
    return 'unknown'
  }

  const [type, format] = mimeParts
  if (typesMap[type]) {
    if (typesMap[type].some((typeFormat) => format.includes(typeFormat))) {
      return type
    }
  }

  return 'unknown'
}
