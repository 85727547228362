import { useCtaMediaBannersList } from '@hooks/CTA/useCtaMediaBannersList'

import { getFileTypeFromMime } from '@utils/getFileTypeFromMime'
import { getColor } from '@utils/strapiCommons/utils'

import MediaBanner, { MediaType } from '@modules/CTA/MediaBanner/MediaBanner'

const CtaMediaBannersList = () => {
  const { data, isOpen, onClose } = useCtaMediaBannersList()

  const backgroundColor = getColor(
    data?.attributes?.banners?.[0].backgroundColor
  )

  const mediaType = getFileTypeFromMime(
    data?.attributes?.banners?.[0].media.data.attributes.mime
  )

  return (
    <MediaBanner
      isOpen={isOpen}
      onClose={onClose}
      mediaType={mediaType as MediaType}
      src={data?.attributes?.banners?.[0].media.data.attributes.url || ''}
      mimeType={data?.attributes?.banners?.[0].media.data.attributes.mime}
      title={data?.attributes?.banners?.[0].title}
      titlePosition={data?.attributes?.banners?.[0].titlePlacement}
      backgroundColor={backgroundColor}
      buttonProps={data?.attributes?.banners?.[0].button}
      bannerName={data?.attributes?.banners?.[0].bannerName}
    />
  )
}

export default CtaMediaBannersList
