import { parseISO } from 'date-fns'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { StrapiCtaBannerList } from '@constants'
import { CTA_POPUP_ALL_PAGES_ALREADY_SHOWN } from '@constants_folder/storageKeys'

import { CtaPopupAllPagesAttributes } from '@_types/strapi/singleTypes'

import { useCtaBannerListQuery } from '@hooks/dataFetching/CTA/useCtaBannerListQuery'
import useLandingInfo from '@hooks/useLandingInfo'

import useBrowserStorage from '@utils/customHooks/useBrowserStorage'
import { shouldRenderCTAByIncludedExcludedUrls } from '@utils/shouldRenderCTAByIncludedExcludedUrls'

const OPEN_MODAL_DELAY = 30 * 1000
const THREE_DAYS_IN_SECONDS = 3 * 86400

export const usePopupAllPagesOpenLogic = () => {
  const { locale } = useRouter()

  const { data, isLoading, isError } =
    useCtaBannerListQuery<CtaPopupAllPagesAttributes>({
      locale,
      slug: StrapiCtaBannerList.CTA_POPUP_ALL_PAGES,
    })

  const timerExpirationCheck =
    data && new Date() > parseISO(data?.timer?.dateEnd)

  const { LANDING_PATH } = useLandingInfo()

  const [isOpen, setIsOpen] = useState(false)

  const [isTimerExpired, setIsTimerExpired] = useState(timerExpirationCheck)
  const [hasBeenShown, setHasBeenShown] = useBrowserStorage({
    storageType: 'sessionStorage',
    key: CTA_POPUP_ALL_PAGES_ALREADY_SHOWN,
    initialValue: false,
    timeToLive: null,
  })
  const [hasBeenShownLocalStorage, setHasBeenShownLocalStorage] =
    useBrowserStorage({
      storageType: 'localStorage',
      key: CTA_POPUP_ALL_PAGES_ALREADY_SHOWN,
      initialValue: false,
      timeToLive: THREE_DAYS_IN_SECONDS,
    })

  const handleClose = () => {
    setIsOpen(false)
    setHasBeenShown(true)
    setHasBeenShownLocalStorage(true)
  }

  useEffect(() => {
    if (hasBeenShown || hasBeenShownLocalStorage) return undefined

    const timeoutId: NodeJS.Timeout = setTimeout(() => {
      setIsOpen(true)
    }, OPEN_MODAL_DELAY)

    return () => clearTimeout(timeoutId)
  }, [hasBeenShown, hasBeenShownLocalStorage])

  useEffect(() => {
    if (!data || isTimerExpired) return undefined

    const interval: NodeJS.Timeout = setInterval(() => {
      setIsTimerExpired(new Date() > parseISO(data?.timer?.dateEnd))
    }, 1000)

    return () => clearInterval(interval)
  }, [isTimerExpired, data, data?.timer?.dateEnd])

  const isDataStatusProblematic = isLoading || isError || !data
  const isInvalidPopupState = data?.isDisabled || !!isTimerExpired
  const isCTAVisibleBasedOnUrl = shouldRenderCTAByIncludedExcludedUrls({
    currentUrl: LANDING_PATH,
    includedUrls: data?.urlsToInclude || [],
    excludedUrls: data?.urlsToExclude || [],
  })

  const shouldRender =
    !isInvalidPopupState && !isDataStatusProblematic && isCTAVisibleBasedOnUrl

  return {
    data,
    handleClose,
    shouldRenderPopUp: shouldRender && isOpen,
    shouldRenderCta: shouldRender && (hasBeenShown || hasBeenShownLocalStorage),
  }
}
