import { useRouter } from 'next/router'

import { StrapiCtaBannerList } from '@constants_folder/strapi_urls'

import { StrapiRecord } from '@_types/landings'

import { useCtaBannerListQuery } from '@hooks/dataFetching/CTA/useCtaBannerListQuery'
import useBreakpoints from '@hooks/useBreakpoints'
import useLandingInfo from '@hooks/useLandingInfo'

import { shouldRenderCTAByIncludedExcludedUrls } from '@utils/shouldRenderCTAByIncludedExcludedUrls'

export interface DisplayOnUrlsList {
  id: number
  urlsToInclude: string[]
  urlsToExclude: string[]
}

interface CtaDownloadAppQrData {
  id: number
  title: string
  isDisabled: boolean
  image: StrapiRecord
  displayOn: DisplayOnUrlsList
  backgroundColor?: string
}

interface UseCtaDownloadAppQrPopup {
  shouldShowPopup: boolean
  data?: CtaDownloadAppQrData
}

const useCtaDownloadAppQrPopup = (): UseCtaDownloadAppQrPopup => {
  const { locale } = useRouter()
  const { LANDING_PATH } = useLandingInfo()
  const { lg: isLgScreen } = useBreakpoints()

  const populateTemplate = {
    fields: ['slug', 'locale'],
    banner: {
      on: {
        'shared.cta-block': {
          populate: ['displayOn', 'image'],
        },
      },
    },
  }

  const { data } = useCtaBannerListQuery<CtaDownloadAppQrData>({
    slug: `${StrapiCtaBannerList.DOWNLOAD_APP_QR_CODE}`,
    populate: {
      ...populateTemplate,
    },
    fields: ['slug', 'locale'],
    locale,
    enabled: isLgScreen,
  })

  if (!data || !isLgScreen || data?.isDisabled) {
    return {
      shouldShowPopup: false,
    }
  }

  const shouldShowPopup = shouldRenderCTAByIncludedExcludedUrls({
    currentUrl: LANDING_PATH,
    includedUrls: data?.displayOn?.urlsToInclude || [],
    excludedUrls: data?.displayOn?.urlsToExclude || [],
  })

  return {
    data,
    shouldShowPopup,
  }
}

export default useCtaDownloadAppQrPopup
