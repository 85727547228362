import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Trans } from '@lingui/macro'
import classnames from 'classnames'
import Link from 'next/link'
import { FC, useContext, useState } from 'react'

import Icon from '@promova/ui/components/Icon/Icon'
import LogoIcon from '@promova/ui/icons/diya.svg'

import { useAppDispatch, useAppSelector } from '@reduxStore/hooks'
import { updateKeeper } from '@reduxStore/reducers/profile/profileThunks'

import { SHOW_FREE_FOR_UKRAINE_BUTTON } from '@constants_folder/remote_config'
import { FREE_FOR_UKRAINE_ROUTE } from '@constants_folder/routes'

import useWebViewMode from '@hooks/useWebViewMode'

import { AuthContext } from '@modules/Auth/AuthProvider'

import styles from './fixed_ukraine_btn.module.scss'

const WAS_CLOSED_UKRAINE_BTN = 'was_closed_ukraine_btn'
const MAX_SHOW_TIMES = 2

type Props = {
  isStatic?: boolean
}

const FreeForUkraineFixedBtn: FC<Props> = ({ isStatic }) => {
  const { isWebViewMode } = useWebViewMode()

  const shouldShowBtnFromRemoteConfig = useFeatureIsOn(
    SHOW_FREE_FOR_UKRAINE_BUTTON
  )

  const hasCoursesPremium = useAppSelector(
    (state) => state.profile.hasCoursesPremium
  )
  const isSubscriptionFetched = useAppSelector(
    (state) => state.profile.isSubscriptionFetched
  )
  const { user, loading } = useContext(AuthContext)
  const dispatch = useAppDispatch()
  const { userProfile } = useAppSelector((state) => state.profile)

  const wasClosedCountData = userProfile?.keeper?.[WAS_CLOSED_UKRAINE_BTN]
  const wasClosedCount = wasClosedCountData
    ? +JSON.parse(wasClosedCountData.value)
    : 0

  const [wasClosed, setWasClosed] = useState(
    typeof window === 'undefined'
      ? false
      : sessionStorage?.getItem(WAS_CLOSED_UKRAINE_BTN) === 'true'
  )

  const handleClose = async () => {
    if (isStatic) return

    setWasClosed(true)
    window?.sessionStorage?.setItem(WAS_CLOSED_UKRAINE_BTN, 'true')

    const token = await user?.getIdToken()
    if (!token) return

    dispatch(
      updateKeeper({
        token,
        data: {
          key: WAS_CLOSED_UKRAINE_BTN,
          payload: String(wasClosedCount + 1),
        },
      })
    )
  }

  const shouldHideFixedBtn = wasClosed || wasClosedCount >= MAX_SHOW_TIMES

  if (
    loading ||
    isWebViewMode ||
    hasCoursesPremium ||
    !shouldShowBtnFromRemoteConfig ||
    (!isStatic && shouldHideFixedBtn) ||
    (user && !isSubscriptionFetched)
  ) {
    return null
  }

  const wrapperClassNames = classnames(styles.fixed_btn, {
    [styles.static]: isStatic,
  })

  return (
    <div className={wrapperClassNames}>
      <Link href={FREE_FOR_UKRAINE_ROUTE} passHref>
        {/* eslint-disable-next-line */}
        <a className={styles.flex_row} onClick={handleClose}>
          <LogoIcon className={styles.btn_log} />
          <div className={styles.btn_text}>
            <Trans>Free for Ukrainians</Trans>
          </div>
          <Icon icon="angle_right" />
        </a>
      </Link>
      {!isStatic && (
        <button
          onClick={handleClose}
          aria-label="cancel button"
          type="button"
          className={styles.cancel_btn}
        >
          <Icon icon="close" size={16} className={styles.cancel_icon} />
        </button>
      )}
    </div>
  )
}

export default FreeForUkraineFixedBtn
