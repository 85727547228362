import { FC } from 'react'
import Marquee from 'react-fast-marquee'

import StarIcon from '@images/english_tutors/star.svg'

import styles from './Ticker.module.scss'

interface Props {
  tickerText: string
}

const NUMBER_OF_TICKER_TEXTS = 10

const RunningText: FC<Props> = ({ tickerText }) => {
  const ticker = [...Array(NUMBER_OF_TICKER_TEXTS)].map((_, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <span key={i} className={styles.scroll_text}>
      {tickerText} <StarIcon className={styles.star_icon} />
    </span>
  ))

  return <Marquee>{ticker}</Marquee>
}

export default RunningText
