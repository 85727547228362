import Link, { LinkProps } from 'next/link'

type LinkWithTextType<T> = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  React.PropsWithChildren<T>

// Custom component with Link that can be wrapped in <Trans> w-out error
const LinkWithinTrans = ({
  href = '',
  children,
  onClick,
  ...restProps
}: LinkWithTextType<LinkProps>) => (
  <Link href={href} passHref>
    <a href={href} onClick={onClick} {...restProps}>
      {children}
    </a>
  </Link>
)

export default LinkWithinTrans
