import classNames from 'classnames'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { FC, Fragment } from 'react'

import { CtaTimerWithButtonAllPagesType } from '@_types/strapi/singleTypes'

import useBreakpoints from '@hooks/useBreakpoints'
import useLandingInfo from '@hooks/useLandingInfo'

import { sendAnalytics } from '@utils/analytics'
import { ColorDataTypes } from '@utils/strapiCommons/types'
import { getColor } from '@utils/strapiCommons/utils'

import TimerBlock from '@templates/Landing/layoutV2/TimerBlock/TimerBlock'

import Button from '@elements/ButtonV2/Button'

import styles from './CtaTimerWithButtonAllPages.module.scss'

type Props = {
  data: CtaTimerWithButtonAllPagesType
  isScroll: boolean
}

const defaultBgColor: ColorDataTypes = {
  name: 'Yellow',
  opacity: 100,
}

const blogPathNames = ['/blog', '/blog/[slug]']

const CtaTimerWithButtonAllPages: FC<Props> = ({ data, isScroll }) => {
  const { timer, button } = data
  const { LANDING_PATH, LANDING_NAME } = useLandingInfo()
  const { xs: isMobile } = useBreakpoints()
  const { pathname } = useRouter()
  const isBlogPath = blogPathNames.includes(pathname)

  const handleSendClickCTAAnalytics = () => {
    sendAnalytics({
      eventName: 'landing_clicked_cta',
      data: {
        landing_name: LANDING_NAME,
        place: `timer(${LANDING_PATH})`,
        text: button?.text,
        type: button?.href,
      },
    })
  }

  const bgColorData = timer?.timerBgColor || defaultBgColor
  const bgColor = getColor(bgColorData)

  const Wrapper = isMobile ? 'div' : Fragment
  const wrapperProps = isMobile
    ? { className: styles.mobile_timer_button_wrap }
    : null

  const CtaClassNames = classNames(styles.cta, {
    [styles.scroll]: isScroll && !isBlogPath,
    [styles.blog_scroll]: isScroll && isBlogPath,
  })

  return (
    <div className={CtaClassNames} style={{ backgroundColor: bgColor }}>
      <div className={styles.title_wrap}>
        <div className={styles.icon_wrap}>
          <Image
            src="/images/cta_with_timer/fireIcon.svg"
            alt="Fire Icon"
            layout="fill"
          />
        </div>
        <p className={styles.title}>{data?.title}</p>
      </div>
      <Wrapper {...wrapperProps}>
        <div className={styles.timer_wrap}>
          <TimerBlock
            disabled={timer?.isDisabled}
            dateStart={timer?.dateStart}
            dateEnd={timer?.dateEnd}
            color={bgColorData}
          />
        </div>
        <div className={styles.button_wrap}>
          <Button
            {...button}
            className={styles.button}
            onClick={handleSendClickCTAAnalytics}
            link={button?.href}
          >
            {button?.label || button?.text}
          </Button>
        </div>
      </Wrapper>
    </div>
  )
}

export default CtaTimerWithButtonAllPages
