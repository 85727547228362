import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { HOME_PATH, StrapiCtaBannerList } from '@constants'

import { CtaBannerForArticleAttributes } from '@_types/strapi/singleTypes'

import { useCtaBannerListQuery } from '@hooks/dataFetching/CTA/useCtaBannerListQuery'
import useBreakpoints from '@hooks/useBreakpoints'

export const useCtaTutoringBannerOpenLogic = () => {
  const { pathname, locale } = useRouter()
  const isHomePath = pathname === HOME_PATH

  const { xs: isMobile } = useBreakpoints()

  const {
    data: ctaTutoringBanner,
    isLoading: isLoadingCtaTutoringBanner,
    isError: isErrorCtaTutoringBanner,
  } = useCtaBannerListQuery<CtaBannerForArticleAttributes>({
    locale,
    enabled: !isHomePath,
    slug: StrapiCtaBannerList.CTA_TUTORING_BANNER,
  })

  return useMemo(() => {
    const isDataLoaded =
      !isLoadingCtaTutoringBanner &&
      !isErrorCtaTutoringBanner &&
      ctaTutoringBanner

    const isPopupStateValid =
      !ctaTutoringBanner?.isDisabled && !isHomePath && !isMobile

    return isDataLoaded && isPopupStateValid
  }, [
    ctaTutoringBanner,
    isMobile,
    isHomePath,
    isLoadingCtaTutoringBanner,
    isErrorCtaTutoringBanner,
  ])
}
