import { useRouter } from 'next/router'
import { FC } from 'react'

import { HOME_PATH, StrapiCtaBannerList } from '@constants'

import { CtaDontGoPopupType } from '@_types/strapi/singleTypes'

import { useCtaBannerListQuery } from '@hooks/dataFetching/CTA/useCtaBannerListQuery'
import useBreakpoints from '@hooks/useBreakpoints'
import useLandingInfo from '@hooks/useLandingInfo'

import CtaEmailSubscribe from '@modules/CTA/CtaEmailSubscribe/CtaEmailSubscribe'
import CtaQueueManager from '@modules/CTA/CtaQueueManager/CtaQueueManager'
import CtaTutoringPopup from '@modules/CTA/CtaTutoringPopup/CtaTutoringPopup'

import { CTAPopupDontGoWrapper } from '../CTAPopupClosePage/CTAPopupDontGoWrapper'

export const CtasWrapper: FC = () => {
  const { pathname } = useRouter()

  const doNotShowOnMainPage = pathname !== HOME_PATH
  const { locale } = useRouter()
  const { lg: isDesktop } = useBreakpoints()
  const { LANDING_PATH } = useLandingInfo()

  const { data, isLoading, isError } =
    useCtaBannerListQuery<CtaDontGoPopupType>({
      locale,
      enabled: LANDING_PATH !== HOME_PATH && isDesktop,
      slug: StrapiCtaBannerList.CTA_DONT_GO_ALL_PAGES,
    })

  return (
    <>
      {doNotShowOnMainPage && (
        <>
          <CTAPopupDontGoWrapper
            data={data}
            isLoading={isLoading}
            isError={isError}
          />
          <CtaTutoringPopup />
        </>
      )}
      <CtaEmailSubscribe />

      <CtaQueueManager />
    </>
  )
}
