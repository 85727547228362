export const RETENO_PDF_LINK_FIELD_ID = 257617
// TODO: delete const above when PGS-1038:CTA Email subscribe will be released
export enum RetenoFieldId {
  PDF_LINK = 257617,
  ORGANIC = 249969,
}

export enum RetenoGroup {
  POPUP_ORGANIC_EMAIL = 'popup_organic_email',
  CONFUSING_WORDS_ORGANIC_EMAIL = 'confusing_words_organic_email',
}

export enum RetenoChannelType {
  EMAIL = 'email',
}
