import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import Image from 'next/image'

import styles from './success_screen.module.scss'

const SuccessScreen = () => {
  const imgWithAnimation = classNames(styles.img_container, styles.img_animate)

  return (
    <div className={styles.container}>
      <div className={imgWithAnimation}>
        <Image
          key="image"
          src="/images/check.png"
          layout="intrinsic"
          objectFit="contain"
          width={70}
          height={70}
          alt=""
        />
      </div>
      <div className={styles.heading}>
        <Trans>Great news!</Trans>
      </div>
      <p className={styles.subtitle}>
        <Trans>
          Your special offers are on their way to your inbox. Check your email!
        </Trans>
      </p>
    </div>
  )
}

export default SuccessScreen
