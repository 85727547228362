import { i18n } from '@lingui/core'
import classNames from 'classnames'
import Link from 'next/link'
import { FC, MouseEvent, TouchEvent } from 'react'
import Lottie from 'react-lottie-player'

import { NavigationLink } from '@_types/navigation'

import styles from './navigation_list.module.scss'

interface Props {
  links: NavigationLink[]
  onClick?: (
    e: MouseEvent<HTMLAnchorElement> | TouchEvent<HTMLAnchorElement>
  ) => void
}

const NavigationList: FC<Props> = ({ links, onClick }) => (
  <ul className={styles.nav_list}>
    {links.map((link, index) => {
      if (!link?.route || !link?.label) return null

      return (
        <li key={link.route} className={styles.list_item}>
          <Link href={link.route || ''}>
            {/* eslint-disable-next-line */}
            <a
              className={styles.navigation_link}
              onClick={onClick}
              onTouchEnd={onClick}
              data-title={link.label}
              data-route={link.route}
            >
              {i18n._(link.label)}
              {link?.animationData && (
                <Lottie
                  animationData={link.animationData}
                  loop
                  play
                  className={classNames(
                    styles.link_animation,
                    styles[`link_animation_${index}`]
                  )}
                />
              )}
            </a>
          </Link>
        </li>
      )
    })}
  </ul>
)

export default NavigationList
