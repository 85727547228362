import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'

interface MountedCtaContextType {
  ctaWithTimer: boolean
  setCtaWithTimer: Dispatch<SetStateAction<boolean>>
  ctaWithRunningText: boolean
  setCtaWithRunningText: Dispatch<SetStateAction<boolean>>
}

const MountedCtaContext = createContext<MountedCtaContextType | null>(null)

const MountedCtaContextProvider: React.FC = ({ children }) => {
  const [ctaWithTimer, setCtaWithTimer] = useState<boolean>(false)
  const [ctaWithRunningText, setCtaWithRunningText] = useState<boolean>(false)

  const contextValue = useMemo(
    () => ({
      ctaWithTimer,
      setCtaWithTimer,
      ctaWithRunningText,
      setCtaWithRunningText,
    }),
    [ctaWithTimer, setCtaWithTimer, ctaWithRunningText, setCtaWithRunningText]
  )

  return (
    <MountedCtaContext.Provider value={contextValue}>
      {children}
    </MountedCtaContext.Provider>
  )
}

const useMountedCtaContext = (): MountedCtaContextType | null =>
  useContext(MountedCtaContext)

export { MountedCtaContextProvider, useMountedCtaContext }
