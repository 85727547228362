import { FC } from 'react'

import useBreakpoints from '@hooks/useBreakpoints'

import CtaBannerMobileOnly from '@modules/CTA/CtaBannerMobileOnly/CtaBannerMobileOnly'

const CtaBannerMobileOnlyWrapper: FC = () => {
  const { xs: isMobile } = useBreakpoints()

  if (!isMobile) return null

  return <CtaBannerMobileOnly />
}

export default CtaBannerMobileOnlyWrapper
