import { useCallback, useEffect, useMemo, useState } from 'react'

import { CTA_POPUP_DONT_GO_ALREADY_SHOWN } from '@constants'

import { CtaDontGoPopupType } from '@_types/strapi/singleTypes'

import { useBeforeNavigationChange } from '@hooks/useBeforeNavigationChange'
import useBreakpoints from '@hooks/useBreakpoints'
import useBrowserStorage from '@hooks/useBrowserStorage'
import useLandingInfo from '@hooks/useLandingInfo'
import useMousePosition from '@hooks/useMousePosition'

import { sendAnalytics } from '@utils/analytics'
import { shouldRenderCTAByIncludedExcludedUrls } from '@utils/shouldRenderCTAByIncludedExcludedUrls'

const Y_OFFSET_TO_SHOW_MODAL = 10
const FIVE_DAYS_IN_SECONDS = 5 * 24 * 60 * 60

export const useDontGoCTAOpenLogic = ({
  data: ctaData,
  isLoading,
  isError,
}: {
  data: CtaDontGoPopupType | undefined
  isLoading: boolean
  isError: boolean
}) => {
  const { lg: isDesktop } = useBreakpoints()
  const { LANDING_PATH } = useLandingInfo()
  const { y: mouseY } = useMousePosition()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [hasBeenShownLocalStorage, setHasBeenShownLocalStorage] =
    useBrowserStorage({
      storageType: 'localStorage',
      key: CTA_POPUP_DONT_GO_ALREADY_SHOWN,
      initialValue: false,
      timeToLive: FIVE_DAYS_IN_SECONDS,
    })

  useEffect(() => {
    if (!mouseY || mouseY >= Y_OFFSET_TO_SHOW_MODAL) return

    const isUrlMatching = shouldRenderCTAByIncludedExcludedUrls({
      currentUrl: LANDING_PATH,
      includedUrls: ctaData?.urlsToInclude || [],
      excludedUrls: ctaData?.urlsToExclude || [],
    })

    if (isUrlMatching) {
      setIsModalOpen(true)
    }
  }, [mouseY, LANDING_PATH, ctaData])

  const handleClose = useCallback(() => {
    setIsModalOpen(false)
    setHasBeenShownLocalStorage(true)

    sendAnalytics({
      eventName: 'gen_saw_quit_popup',
      data: {
        page_path: LANDING_PATH,
        text: ctaData?.button?.text,
        type: ctaData?.button?.href,
      },
    })
  }, [setHasBeenShownLocalStorage, LANDING_PATH, ctaData])

  useBeforeNavigationChange({ onBeforeNavigationChange: handleClose })

  const isOpen: boolean = useMemo(
    () =>
      isModalOpen &&
      !isLoading &&
      !isError &&
      !!ctaData &&
      !hasBeenShownLocalStorage &&
      isDesktop,
    [
      isModalOpen,
      hasBeenShownLocalStorage,
      isDesktop,
      ctaData,
      isLoading,
      isError,
    ]
  )

  return { isOpen, handleClose }
}
