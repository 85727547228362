import { FC } from 'react'

import { CtaPopupAllPagesAttributes } from '@_types/strapi/singleTypes'

import CtaBlockAllPages from '@modules/CTA/CtaPopupAllPages/CtaNavigationBlock/CtaBlockAllPages'

interface Props {
  data: CtaPopupAllPagesAttributes | undefined
  isOpen: boolean
}

const CtaBlockAllPagesWrapper: FC<Props> = ({ data, isOpen }) => {
  if (!data || !isOpen) return null
  return (
    <CtaBlockAllPages
      timer={data?.timer}
      title={data?.title}
      button={data?.button}
    />
  )
}

export default CtaBlockAllPagesWrapper
