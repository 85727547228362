import classNames from 'classnames'
import { FC, MouseEvent, TouchEvent, useEffect, useRef } from 'react'

import { useClickOutside } from '@promova/utils/customHooks/customHooks'
import useWindowHeight from '@promova/utils/customHooks/useWindowHeight'

import { NavigationLink } from '@_types/navigation'

import NavigationActions from '@templates/LandingNavigation/NavigationActions/NavigationActions'
import NavigationList from '@templates/LandingNavigation/NavigationList/NavigationList'
import { mainLinks } from '@templates/LandingNavigation/links'

import CtaSection from './CtaSection/CtaSection'
import styles from './side_navigation.module.scss'

export interface SideNavigationProps {
  active: boolean
  links?: NavigationLink[]
  onClose: () => void
  onNavLinkClick?: (
    e: MouseEvent<HTMLAnchorElement> | TouchEvent<HTMLAnchorElement>
  ) => void
  availableLocales?: string[]
  showFreeUkraineButton?: boolean
  showLanguageSwitch?: boolean
}

const SideNavigation: FC<SideNavigationProps> = ({
  active,
  links = mainLinks,
  onNavLinkClick,
  onClose,
  availableLocales,
  showLanguageSwitch = true,
  showFreeUkraineButton = true,
}) => {
  const sideNavRef = useRef<HTMLDivElement>(null)

  const { windowHeight } = useWindowHeight()

  useEffect(() => {
    document.body.style.overflow = active ? 'hidden' : ''

    return () => {
      document.body.style.overflow = ''
    }
  }, [active])

  useClickOutside(active, sideNavRef, onClose)

  const sideNavClassNames = classNames(styles.side_nav, {
    [styles.side_nav_active]: active,
  })

  const sideNavBackClassNames = classNames(styles.back, {
    [styles.back_active]: active,
  })

  const handleNavLinkClick = (
    e: MouseEvent<HTMLAnchorElement> | TouchEvent<HTMLAnchorElement>
  ) => {
    onNavLinkClick?.(e)
    onClose()
  }

  return (
    <>
      <div className={sideNavBackClassNames} />
      <div
        className={sideNavClassNames}
        ref={sideNavRef}
        style={{ height: windowHeight, minHeight: windowHeight }}
      >
        <button
          type="button"
          onClick={onClose}
          aria-label=" close side navigation"
          className={styles.close_btn}
        />
        <NavigationList onClick={handleNavLinkClick} links={links} />
        <CtaSection />
        <NavigationActions
          onCloseSidebar={onClose}
          availableLocales={availableLocales}
          showLanguageSwitch={showLanguageSwitch}
          showFreeUkraineButton={showFreeUkraineButton}
        />
      </div>
    </>
  )
}

export default SideNavigation
