import NextLink from 'next/link'
import React, { FC } from 'react'

import Icon from '@promova/ui/components/Icon/Icon'

import { Link, Timer } from '@_types/strapi/componentTypes'

import useBreakpoints from '@hooks/useBreakpoints'

import { ColorDataTypes } from '@utils/strapiCommons/types'
import { getColor } from '@utils/strapiCommons/utils'

import TimerBlock from '@templates/Landing/layoutV2/TimerBlock/TimerBlock'

import Button from '@elements/ButtonV2/Button'

import styles from './cta_navigation_block.module.scss'

type Props = {
  timer: Timer
  title: string
  button?: Partial<Link>
}

const defaultBgColor: ColorDataTypes = {
  name: 'Yellow',
  opacity: 100,
}

const CtaBlockAllPages: FC<Props> = ({ timer, button, title }) => {
  const bgColorData = timer?.timerBgColor || defaultBgColor
  const bgColor = getColor(bgColorData)

  const { xs: isMobile } = useBreakpoints()

  const linkProps = {
    ...button,
    id: String(button?.id),
    href: button?.href as string,
  }

  const Content = (
    <div className={styles.cta} style={{ backgroundColor: bgColor }}>
      <p className={styles.title}>{title}</p>
      <div className={styles.timer_wrap}>
        <TimerBlock
          disabled={timer?.isDisabled}
          dateStart={timer?.dateStart}
          dateEnd={timer?.dateEnd}
          color={bgColorData}
        />
      </div>
      <Icon
        icon="angle_right"
        className={`${styles.animated_icon_right} ${styles.icon_mobile}`}
      />
      <div className={styles.desktop_button_wrap}>
        <Icon icon="angle_right" className={styles.animated_icon_left} />
        <Button {...button} link={button?.href} className={styles.button}>
          {button?.label}
        </Button>
        <Icon icon="angle_left" className={styles.animated_icon_right} />
      </div>
    </div>
  )

  if (isMobile) {
    return <NextLink {...linkProps}>{Content}</NextLink>
  }
  return <div>{Content}</div>
}

export default CtaBlockAllPages
