import { DEFAULT_LOCALE } from '@constants_folder/common'
import { CTA_EMAIL_SUBSCRIBE_LIST } from '@constants_folder/strapi_urls'

import { StrapiBaseCollectionTypeResponse } from '@_types/strapi/common'

import { CTA_REFETCH_ATTEMPTS, HOURS_24 } from '@hooks/dataFetching/constants'
import { useQueryFactory } from '@hooks/dataFetching/useQueryFactory'
import { fetchStrapiViaProxy } from '@hooks/dataFetching/utils'

interface Props {
  locale?: string | undefined
  populate?: Record<string, any> | string
  filters?: Record<string, any>
  fields?: string | string[]
  enabled?: boolean
}

export const useCtaEmailSubscribeQuery = <T>({
  locale = DEFAULT_LOCALE,
  populate,
  filters = {},
  fields,
  enabled = true,
}: Props) =>
  useQueryFactory(
    [CTA_EMAIL_SUBSCRIBE_LIST, locale],
    (): Promise<StrapiBaseCollectionTypeResponse<T>> =>
      fetchStrapiViaProxy({
        url: CTA_EMAIL_SUBSCRIBE_LIST,
        urlParamsObject: {
          populate,
          locale,
          pagination: {
            limit: '-1',
          },
          filters: {
            ...filters,
          },
          sort: 'updatedAt:DESC',
          fields,
        },
      })
  )({
    enabled,
    cacheTime: HOURS_24,
    staleTime: HOURS_24,
    retry: CTA_REFETCH_ATTEMPTS,
  })
