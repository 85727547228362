import { FC } from 'react'

import styles from './loader.module.scss'

interface Props {
  isLoading: boolean
  position?: 'fixed' | 'relative'
}

const Loader: FC<Props> = ({ isLoading, position = 'fixed' }) =>
  isLoading ? (
    <div className={`${styles.loader_wrapper} ${styles[`loader_${position}`]}`}>
      <svg
        className={styles.stretch_loader}
        viewBox="25 25 50 50"
        strokeWidth="5"
      >
        <circle cx="50" cy="50" r="20" />
      </svg>
    </div>
  ) : null

export default Loader
