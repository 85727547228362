import { FC, useCallback } from 'react'

import { CtaDontGoPopupType } from '@_types/strapi/singleTypes'

import { useDontGoCTAOpenLogic } from '@hooks/CTA/useDontGoCTAOpenLogic'
import useLandingInfo from '@hooks/useLandingInfo'

import { sendAnalytics } from '@utils/analytics'

import CTAPopupDontGo from '@modules/CTA/CTAPopupClosePage/CTAPopupDontGo'

type Props = {
  data?: CtaDontGoPopupType
  isLoading: boolean
  isError: boolean
}

export const CTAPopupDontGoWrapper: FC<Props> = ({
  data,
  isLoading,
  isError,
}) => {
  const { LANDING_PATH } = useLandingInfo()

  const { isOpen, handleClose } = useDontGoCTAOpenLogic({
    data,
    isLoading,
    isError,
  })

  const handleSendOnbEvent = useCallback(() => {
    sendAnalytics({
      eventName: 'gen_clicked_cta',
      data: {
        place: `pop-up(${LANDING_PATH})`,
        text: data?.button?.text,
        type: data?.button?.href,
      },
    })
  }, [LANDING_PATH, data])

  if (!data || isLoading || isError) return null

  return (
    <div>
      {!data.isDisabled && (
        <CTAPopupDontGo
          data={data}
          isModalOpen={isOpen}
          handleClose={handleClose}
          handleCtaClick={handleSendOnbEvent}
        />
      )}
    </div>
  )
}
