import { Trans } from '@lingui/macro'
import { CSSProperties, FC, MouseEvent, SyntheticEvent } from 'react'
import QRCode from 'react-qr-code'

import { DOWNLOAD_LINKS, Event, storeLinks } from '@constants'

import { sendAnalytics } from '@utils/analytics'
import { createLinkWithUtms } from '@utils/createLinkWithUtms'

import styles from './download_screen.module.scss'

type Props = {
  onLinkClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  qrCodeSize?: number
  qrCodeBgColor?: CSSProperties['color']
  qrCodeLink?: string
}

const QR_CODE_SIZE = 248

const DownloadScreen: FC<Props> = (props) => {
  const {
    onLinkClick,
    qrCodeSize = QR_CODE_SIZE,
    qrCodeBgColor = 'transparent',
    qrCodeLink = storeLinks.universal,
  } = props

  const handleSendAnalytics = (e?: SyntheticEvent<HTMLElement>) => {
    const store = e?.currentTarget?.dataset?.store
    sendAnalytics({
      eventName: Event.GEN_DEEPLINK_CLICKED,
      data: {
        store,
      },
    })
  }

  const handleLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    handleSendAnalytics(e)
    onLinkClick?.(e)
  }

  return (
    <div className={styles.container}>
      <div className={styles.qr}>
        <h2 className={styles.title}>
          <Trans>
            Scan the QR code <br /> to download <br />
            the App
          </Trans>
        </h2>
        <QRCode
          value={createLinkWithUtms(qrCodeLink)}
          bgColor={qrCodeBgColor}
          size={qrCodeSize}
        />
      </div>
      <div className={styles.links}>
        <h2 className={styles.links_title}>
          <Trans>Or download Promova on your preferred platform</Trans>
        </h2>
        <div className={styles.links_container}>
          {DOWNLOAD_LINKS.map(({ Icon, link, id }: Record<string, any>) => (
            <a
              key={link}
              onClick={handleLinkClick}
              href={createLinkWithUtms(link)}
              data-store={id}
              target="_blank"
              className={styles.link}
              rel="noreferrer"
            >
              <Icon />
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DownloadScreen
