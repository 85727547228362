import { CSSProperties, FC, useEffect } from 'react'

import useCountdown from '@hooks/useCountdown'

import { addTimeSlugToLocalStorage } from '@modules/CTA/CtaEmailSubscribe/utils'

import styles from './timer_block.module.scss'

interface Props {
  bannerSlug?: string
  text?: string
  startCount: number
  bannerTimerSeconds?: number
  backgroundColor?: CSSProperties['backgroundColor']
  onTimesUp: () => void
  isPaused?: boolean
}

const TimerBlock: FC<Props> = ({
  bannerSlug,
  text,
  startCount,
  bannerTimerSeconds,
  backgroundColor,
  onTimesUp,
  isPaused,
}) => {
  const { current, isOver, pause } = useCountdown(0, startCount)

  useEffect(() => {
    if (isPaused) {
      pause()
    }

    if (isOver || startCount === 0) {
      onTimesUp?.()
    }
  }, [isOver, isPaused, onTimesUp, pause, startCount])

  useEffect(
    () => () => {
      if (!bannerSlug || parseInt(current.toString(), 10) < 0) return
      addTimeSlugToLocalStorage(bannerSlug, current.toString())
    },
    [bannerSlug, current]
  )

  if (bannerTimerSeconds === 0 || !startCount || startCount <= 0) return null

  return (
    <div className={styles.timer_container} style={{ backgroundColor }}>
      <div className={styles.timer_count}>{current}</div>
      <div className={styles.timer_text}>{text}</div>
    </div>
  )
}

export default TimerBlock
