import { useEffect, useState } from 'react'

const useBrowserLanguage = (): string => {
  const [browserLanguage, setBrowserLanguage] = useState('')

  useEffect(() => {
    const language = window.navigator.language.slice(0, 2)

    setBrowserLanguage(language)
  }, [])

  return browserLanguage
}

export default useBrowserLanguage
