import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import classNames from 'classnames'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'

import Button from '@promova/ui/components/Button/Button'
import Checkbox from '@promova/ui/elements/Checkbox/Checkbox'

import { EventProperty } from '@constants_folder/analyticsEvents'
import { EMAIL_VALIDATE_REGEX_PATTERN } from '@constants_folder/regex'

import { setUserProperties } from '@utils/analytics'

import { EmailSubscribeFormValues } from '@modules/CTA/CtaEmailSubscribe/types/CTAEmailSubscribeListTypes'

import ErrorStateText from '@elements/ErrorStateText/ErrorStateText'
import Input from '@elements/Input/Input'
import LinkWithinTrans from '@elements/LinkWithinTrans/LinkWithinTrans'

import styles from './email_subscribe_form.module.scss'

const EMAIL_MUST_BE_VALID = t`Please, enter valid email`
const FIELD_SHOULD_NOT_BE_EMPTY = t`This field shouldn’t be empty`
const AGREE_WITH_TERMS = t`Please check this box if you want to proceed`

interface Props {
  emailLabel?: string
  buttonText?: string
  onFormSubmit?: (values: EmailSubscribeFormValues) => Promise<void>
}
const EmailSubscribeForm: FC<Props> = ({
  emailLabel,
  buttonText,
  onFormSubmit,
}) => {
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<EmailSubscribeFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      agreeWithTerms: false,
    },
  })

  const handleSubscribeClick = async (values: EmailSubscribeFormValues) => {
    setUserProperties({
      [EventProperty.EMAIL_NOTIFICATION]: values.email,
    })

    await onFormSubmit?.(values)
  }

  const errorClassName = classNames({
    [styles.error_box]: Object.keys(errors).length > 0,
  })

  return (
    <form className={styles.form} onSubmit={handleSubmit(handleSubscribeClick)}>
      <Controller
        name="email"
        control={control}
        rules={{
          required: i18n._(FIELD_SHOULD_NOT_BE_EMPTY),
          pattern: {
            value: EMAIL_VALIDATE_REGEX_PATTERN,
            message: i18n._(EMAIL_MUST_BE_VALID),
          },
        }}
        render={({ field, fieldState }) => (
          <Input
            {...field}
            ref={null}
            type="email"
            className={styles.input_wrapper}
            label={emailLabel}
            placeholder="name@mail.com"
            labelClassName={styles.email_label}
            errorBoxClassName={errorClassName}
            error={fieldState?.error?.message}
          />
        )}
      />
      <Controller
        name="agreeWithTerms"
        control={control}
        rules={{
          required: i18n._(AGREE_WITH_TERMS),
        }}
        render={({ field, fieldState }) => (
          <>
            <div className={styles.agreement_wrapper}>
              <Checkbox
                {...field}
                ref={null}
                checked={field.value}
                isError={!!fieldState.error}
                className={styles.agreement_checkbox}
              >
                <div className={styles.agreement_label}>
                  <Trans>
                    I agree to the processing of my personal data and I am
                    familiar with the{' '}
                    <LinkWithinTrans
                      href="/terms/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`${styles.underline}`}
                    >
                      terms and conditions of use Promova
                    </LinkWithinTrans>
                  </Trans>
                </div>
              </Checkbox>
            </div>
            {fieldState?.error?.message && (
              <ErrorStateText
                text={fieldState?.error?.message}
                className={styles.agreement_error}
              />
            )}
          </>
        )}
      />

      <Button
        type="submit"
        size="m"
        contentWidth
        className={styles.button}
        loading={isSubmitting}
        disabled={isSubmitting}
      >
        {buttonText}
      </Button>
    </form>
  )
}

export default EmailSubscribeForm
