import { DEFAULT_LOCALE } from '@constants'

import { fetchStrapi, UrlParamsObject } from '@strapi/strapi'

const PROXY_PREFIX = '/cms'

interface fetcherParams {
  url: string
  urlParamsObject?: UrlParamsObject
  options?: Record<string, any>
  attempts?: number
  IS_PROXY?: boolean
}

export const fetchStrapiViaProxy = async ({
  url,
  urlParamsObject,
  options = {},
  attempts = 0,
  IS_PROXY = true,
}: fetcherParams) =>
  fetchStrapi(
    `${IS_PROXY ? PROXY_PREFIX : ''}${url}`,
    {
      ...urlParamsObject,
      locale: urlParamsObject?.locale || DEFAULT_LOCALE,
    },
    options,
    attempts,
    IS_PROXY
  )
