import { NextRouter } from 'next/router'
import qs from 'qs'

/**
 * Get full Strapi URL from path
 * @param {string} path Path of the URL
 * @returns {string} Full Strapi URL
 */
export function getStrapiURL(path = '') {
  return `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${path}`
}

export function getStrapiProxyURL(path = '') {
  return `${process.env.NEXT_PUBLIC_STRAPI_PROXY_URL}${path}`
}

/**
 * Helper to make GET requests to Strapi API endpoints
 * @param {string} path Path of the API route
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {Object} options Options passed to fetch
 * @returns Parsed API call response
 */

const MAX_ATTEMPTS = 5

export type UrlParamsObject = Record<string, any>

type FetchStrapi = (
  path: string,
  urlParamsObject?: UrlParamsObject,
  options?: Record<string, any>,
  attempt?: number,
  isProxy?: boolean,
  isFallbackForDefaultLocale?: boolean
) => Promise<any>

export type ImageFormat = 'small' | 'medium' | 'full' | 'thumbnail' | 'large'

const SEO_IMAGE_PATHS = [
  '/english-grammar',
  '/blog',
  '/alphabet',
  '/english-vocabulary',
  '/press',
]

export const fetchStrapi: FetchStrapi = async (
  path,
  urlParamsObject = {},
  options = {},
  attempt = 0,
  isProxy = false,
  isFallbackForDefaultLocale = true
) => {
  // Merge default and user options
  const mergedOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  }
  const urlParamsWithDefaultLocale = { ...urlParamsObject, locale: 'en' }
  let error = false
  // Build request URL
  const queryString = qs.stringify(urlParamsObject)

  const requestUrlNoProxy = `${getStrapiURL(
    `/api${path}${queryString ? `?${queryString}` : ''}`
  )}`

  const requestUrlProxy = `${getStrapiProxyURL(
    `/api${path}${queryString ? `?${queryString}` : ''}`
  )}`

  const requestUrl = isProxy ? requestUrlProxy : requestUrlNoProxy

  // Trigger API call
  const response = await fetch(requestUrl, mergedOptions)
  // Handle response
  if (!response.ok) {
    error = true
  }

  const urlParams = isFallbackForDefaultLocale
    ? urlParamsWithDefaultLocale
    : urlParamsObject

  try {
    const data = await response.json()
    if (!data?.data && !options.method && attempt < MAX_ATTEMPTS) {
      return await fetchStrapi(
        path,
        urlParams,
        options,
        attempt + 1,
        isProxy,
        isFallbackForDefaultLocale
      )
    }
    return { ...data, error }
  } catch {
    return {}
  }
}

export function getStrapiMedia(media: Record<string, any>, format = 'full') {
  if (typeof media !== 'object') return media || '' // return empty string if media is undefined

  const imageUrls: { [key: string]: string } = {
    full: media?.data?.attributes?.url,
    medium:
      media?.data?.attributes?.formats?.medium?.url ||
      media?.data?.attributes?.url,
    small:
      media?.data?.attributes?.formats?.small?.url ||
      media?.data?.attributes?.formats?.medium?.url ||
      media?.data?.attributes?.url,
    thumbnail:
      media?.data?.attributes?.formats?.thumbnail?.url ||
      media?.data?.attributes?.formats?.small?.url ||
      media?.data?.attributes?.formats?.medium?.url ||
      media?.data?.attributes?.url,
  }
  const url = imageUrls[format] || ''

  return url.startsWith('/') ? getStrapiURL(url) : url
}

const getImagePath = (
  formatKey: ImageFormat,
  formatData: Record<string, Record<string, string>>
): string => {
  if (formatData && formatData[formatKey]) {
    const { hash, ext } = formatData[formatKey]

    return `${hash}${ext}`
  }
  return ''
}

export const getCDNImageSrc = (
  image: Record<string, any>,
  format: ImageFormat = 'full'
) => {
  if (!image.data || !image.data.attributes) return ''

  const formatsData = image.data.attributes.formats
  const imageHash = image.data.attributes?.hash
  const fallBackUrl = image.data.attributes.url

  const defaultPath = imageHash
    ? `${image.data.attributes.hash}${image.data.attributes.ext}`
    : getImagePath('large', formatsData) || getImagePath('full', formatsData)
  const mediumPath = getImagePath('medium', formatsData) || defaultPath
  const smallPath = getImagePath('small', formatsData) || mediumPath
  const thumbnailPath = getImagePath('thumbnail', formatsData) || smallPath

  const imagePath: { [key: string]: string } = {
    full: defaultPath,
    medium: mediumPath,
    small: smallPath,
    thumbnail: thumbnailPath,
  }

  const path = imagePath[format] || defaultPath
  const imageSrc = path
    ? `${process.env.NEXT_PUBLIC_STRAPI_CDN_MEDIA_URL}/${path}`
    : fallBackUrl

  return imageSrc
}

export const getIsSeoImagePath = (router: NextRouter) =>
  SEO_IMAGE_PATHS.some((path) => router.pathname.startsWith(path))
