import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

interface CtaComponent {
  id: string
  ctaComponent: ReactNode
}

interface CtaQueueContextType {
  ctaQueue: CtaComponent[]
  addCta: (cta: CtaComponent) => void
  removeCta: (id: string) => void
  clearCtaQueue: () => void
  currentCta: CtaComponent | null
  closeCurrentCta: () => void
  setAsCurrentCta: (cta: CtaComponent) => void
}

const CtaQueueContext = createContext<CtaQueueContextType | undefined>(
  undefined
)

export const useCtaQueue = (): CtaQueueContextType => {
  const context = useContext(CtaQueueContext)
  if (!context) {
    throw new Error('useCtaQueue must be used within a CtaQueueProvider')
  }
  return context
}

export const CtaQueueProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [ctaQueue, setCtaQueue] = useState<CtaComponent[]>([])
  const [currentCta, setCurrentCta] = useState<CtaComponent | null>(null)

  const addCta = useCallback(
    (newCta: CtaComponent) => {
      const existsInCurrentCtas = currentCta?.id === newCta.id
      const existsInQueue = ctaQueue.some(
        (queueCta) => queueCta.id === newCta.id
      )

      if (!existsInCurrentCtas && !existsInQueue) {
        setCtaQueue((prevQueue) => [...prevQueue, newCta])
      }
    },
    [ctaQueue, currentCta?.id]
  )

  const closeCurrentCta = () => {
    setCurrentCta(null)
  }

  const setAsCurrentCta = useCallback((cta: CtaComponent) => {
    setCurrentCta(cta)
  }, [])

  const removeCta = useCallback(
    (id: string) => {
      if (currentCta?.id === id) {
        closeCurrentCta()
      }
      if (ctaQueue.find((cta) => cta.id === id)) {
        const filteredCtaQueueList = ctaQueue.filter((cta) => cta.id !== id)
        setCtaQueue(filteredCtaQueueList)
      }
    },
    [ctaQueue, currentCta?.id]
  )

  const clearCtaQueue = () => {
    setCtaQueue([])
    setCurrentCta(null)
  }

  useEffect(() => {
    if (ctaQueue.length > 0 && currentCta === null) {
      const [nextCta, ...restQueue] = ctaQueue
      setCurrentCta(nextCta)
      setCtaQueue(restQueue)
    }
  }, [ctaQueue, currentCta])

  const value = useMemo(
    () => ({
      ctaQueue,
      clearCtaQueue,
      currentCta,
      closeCurrentCta,
      setAsCurrentCta,
      addCta,
      removeCta,
    }),
    [addCta, removeCta, ctaQueue, currentCta, setAsCurrentCta]
  )

  return (
    <CtaQueueContext.Provider value={value}>
      {children}
    </CtaQueueContext.Provider>
  )
}
