import classNames from 'classnames'
import { CSSProperties, FC, ReactNode, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

import Icon from '@promova/ui/components/Icon/Icon'
import Loader from '@promova/ui/elements/Loader/Loader'

import toggleBodyClassName from '@utils/toggleBodyClassName'

import styles from './pop_up.module.scss'

type Props = {
  handleClose: () => void
  children: ReactNode
  overlayClasName?: string
  popoverClassName?: string
  popoverStyles?: CSSProperties
  isLoading?: boolean
  closeByClickOutside?: boolean
  withOverlay?: boolean
  onOverlayClick?: () => void
  preventScroll?: boolean
}

const PopUp: FC<Props> = ({
  children,
  handleClose,
  overlayClasName = '',
  popoverClassName = '',
  popoverStyles = {},
  isLoading,
  closeByClickOutside = true,
  withOverlay = true,
  onOverlayClick,
  preventScroll = true,
}) => {
  const overlayRef = useRef(null)

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (overlayRef.current === e.target && onOverlayClick) {
        onOverlayClick()
      }

      if (overlayRef.current === e.target && closeByClickOutside) {
        handleClose()
      }
    }

    document.addEventListener('click', handleClick)

    return () => document.removeEventListener('click', handleClick)
  }, [handleClose, closeByClickOutside, onOverlayClick])

  useEffect(() => {
    if (preventScroll) {
      toggleBodyClassName('stop_scrolling')

      return () => toggleBodyClassName('stop_scrolling')
    }

    return undefined
  }, [preventScroll])

  const overlayClassNames = classNames({
    [styles.overlay]: Boolean(withOverlay),
    [overlayClasName]: Boolean(overlayClasName),
  })

  const popoverClassNames = classNames(styles.popover, {
    [popoverClassName]: Boolean(popoverClassName),
  })

  if (typeof window !== 'object') return null

  return createPortal(
    <div className={overlayClassNames} ref={overlayRef}>
      {isLoading ? (
        <Loader isLoading />
      ) : (
        <div className={popoverClassNames} style={popoverStyles}>
          <button
            onClick={handleClose}
            type="button"
            className={styles.close_button}
          >
            <Icon icon="close" />
          </button>
          {children}
        </div>
      )}
    </div>,
    document.getElementById('popup-root') || document?.body
  )
}

export default PopUp
