import classNames from 'classnames'
import Image from 'next/image'
import { CSSProperties, FC, useState } from 'react'

import useLandingInfo from '@hooks/useLandingInfo'

import { sendAnalytics } from '@utils/analytics'

import { MediaBannerButton } from '@modules/CTA/types/CTAMediaBannerListTypes'
import PopUp from '@modules/PopUp/PopUp'
import VideoPlayer from '@modules/VideoPlayer/VideoPlayer'

import Button from '@elements/ButtonV2/Button'

import styles from './media_banner.module.scss'

export enum MediaType {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
}

type TitlePosition = 'top' | 'bottom'

interface Props {
  src: string
  mediaType: MediaType
  isOpen: boolean
  onClose: () => void
  mimeType?: string
  title?: string
  titlePosition?: TitlePosition
  backgroundColor?: string
  buttonProps?: MediaBannerButton
  bannerName?: string
}

const MediaBanner: FC<Props> = ({
  src,
  mimeType = 'video/mp4',
  mediaType,
  isOpen,
  onClose,
  title,
  titlePosition = 'top',
  backgroundColor,
  buttonProps,
  bannerName,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isReadyToPlay, setIsReadyToPlay] = useState(
    mediaType !== MediaType.VIDEO
  )

  const { LANDING_PATH, LANDING_NAME } = useLandingInfo()

  const popoverClassNames = classNames(
    styles.popover_default,
    styles.popover_animation,
    {
      [styles.popover_fullscreen]: isFullScreen,
    }
  )
  const titleClassNames = classNames(styles.title, {
    [styles.title_fullscreen]: isFullScreen,
  })

  const handleReadyToPlay = () => {
    /* setIsReadyToPlay sets only when we get video metadata
     * We need video sizes to prevent rapidly increasing container height)
     */
    setIsReadyToPlay(true)
  }

  const handleFullscreenMode = () => {
    sendAnalytics({
      eventName: 'gen_fullscreen_opened',
      data: {
        place: 'landing',
        page_path: LANDING_PATH,
        text: buttonProps?.text,
        type: `${mediaType} banner`,
        block_name: bannerName,
      },
    })

    setIsFullScreen((isFullscreen) => !isFullscreen)
  }

  const handleClosePopup = () => {
    sendAnalytics({
      eventName: 'gen_popup_dismissed',
      data: {
        place: 'landing',
        page_path: LANDING_PATH,
        text: buttonProps?.text,
        type: `${mediaType} banner`,
        block_name: bannerName,
      },
    })

    onClose?.()

    if (isFullScreen) {
      setIsFullScreen(false)
    }
  }

  const handleClickCtaButton = () => {
    sendAnalytics({
      eventName: 'gen_clicked_cta',
      data: {
        page_path: LANDING_NAME,
        redirect_to: buttonProps?.href,
        text: buttonProps?.text,
        block_name: bannerName,
      },
    })
  }

  const isTitleOnBottom = titlePosition === 'bottom'

  const isVisible = isReadyToPlay && isOpen
  const popoverStyles: CSSProperties = {
    backgroundColor,
    visibility: isVisible ? 'visible' : 'hidden',
    opacity: isVisible ? 1 : 0,
    zIndex: isVisible ? 100 : -100,
  }

  return (
    <PopUp
      preventScroll={isFullScreen}
      withOverlay={isFullScreen}
      handleClose={handleClosePopup}
      closeByClickOutside={false}
      onOverlayClick={handleFullscreenMode}
      popoverClassName={popoverClassNames}
      popoverStyles={popoverStyles}
    >
      {title && !isTitleOnBottom && (
        <div className={titleClassNames}>{title}</div>
      )}
      {mediaType === MediaType.VIDEO && (
        <VideoPlayer
          containerClassName={styles.video_container}
          autoPlay
          mutedOnStart
          preventFullscreenToggle
          onFullScreenMode={handleFullscreenMode}
          isFullScreen={isFullScreen}
          src={src}
          mimeType={mimeType}
          isStopped={!isOpen}
          onReadyToPlay={handleReadyToPlay}
        />
      )}
      {mediaType === MediaType.IMAGE && (
        <figure className={styles.image_wrapper}>
          <Image
            src={src}
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
        </figure>
      )}
      {title && isTitleOnBottom && (
        <div className={titleClassNames}>{title}</div>
      )}

      <Button
        withScaling
        size="m"
        className={styles.cta_button}
        link={buttonProps?.href}
        data-event-type={buttonProps?.eventType}
        data-event-text={buttonProps?.text}
        isExternal={buttonProps?.isExternal}
        onClick={handleClickCtaButton}
      >
        {buttonProps?.label}
      </Button>
    </PopUp>
  )
}

export default MediaBanner
