import classNames from 'classnames'
import { FC, Fragment, useMemo } from 'react'

import sortByOrder from '@promova/utils/sortByOrder'

import { LinksListType } from '@_types/landings'

import LinksList from '@modules/Footer/LinksList/LinksList'

import styles from './links_columns.module.scss'

type Props = {
  linksList: LinksListType[]
  customClassNames?: {
    column?: string
    title?: string
    item?: string
    wrapper?: string
  }
  cookieSettings?: {
    title?: string
    column?: number
  }
}

const LinksColumns: FC<Props> = ({
  linksList,
  customClassNames,
  cookieSettings,
  children,
}) => {
  const sortedLinksColumns = useMemo(() => sortByOrder(linksList), [linksList])

  const titleClassNames = classNames(styles.title, {
    [customClassNames?.title as string]: !!customClassNames?.title,
  })

  const columnClassNames = classNames(styles.column, {
    [customClassNames?.column as string]: !!customClassNames?.column,
  })

  const wrapperClassNames = classNames(styles.wrapper, {
    [customClassNames?.wrapper as string]: !!customClassNames?.wrapper,
  })

  return (
    <div className={wrapperClassNames}>
      {sortedLinksColumns?.map((column, index) => (
        <Fragment key={column.id}>
          {!column.isHidden && (
            <nav className={columnClassNames}>
              <div className={titleClassNames}>{column.title}</div>
              <LinksList
                links={column.links}
                customLinkClassName={customClassNames?.item}
                cookieLinkTitle={
                  cookieSettings?.column === index + 1
                    ? cookieSettings?.title
                    : ''
                }
              />
            </nav>
          )}
        </Fragment>
      ))}
      {children}
    </div>
  )
}

export default LinksColumns
