import NextImage, { ImageProps } from 'next/image'
import { useRouter } from 'next/router'
import { FC } from 'react'

import {
  ImageFormat,
  getCDNImageSrc,
  getIsSeoImagePath,
  getStrapiMedia,
} from '@strapi/strapi'

type StrapiImageProps = {
  image: any
  layout?: ImageProps['layout']
  fit?: ImageProps['objectFit']
  format?: ImageFormat
  priority?: boolean
  position?: ImageProps['objectPosition']
  customWidth?: number
  customHeight?: number
  customAlt?: string
  usePlaceholder?: boolean
  sizes?: string
  unoptimized?: boolean
}

const StrapiImage: FC<StrapiImageProps> = ({
  image,
  layout = 'responsive',
  fit = 'contain',
  format,
  priority = false,
  position,
  customWidth,
  customHeight,
  customAlt,
  usePlaceholder,
  sizes = '',
  unoptimized,
}) => {
  const router = useRouter()

  if (!image?.data?.attributes) return null

  const isSeoImagePath = getIsSeoImagePath(router)

  const { alternativeText, width, height } = image.data.attributes

  const src = isSeoImagePath
    ? getStrapiMedia(image, format)
    : getCDNImageSrc(image, format)

  return (
    <NextImage
      priority={isSeoImagePath || priority}
      layout={!!width && !!height ? layout : 'fill'}
      width={customWidth || (layout !== 'fill' && width)}
      height={customHeight || (layout !== 'fill' && height)}
      objectFit={fit}
      objectPosition={position}
      src={src}
      blurDataURL={getStrapiMedia(image, 'thumbnail')}
      alt={customAlt || alternativeText || ''}
      placeholder={usePlaceholder ? 'blur' : 'empty'}
      sizes={sizes}
      unoptimized={unoptimized}
    />
  )
}

export default StrapiImage
