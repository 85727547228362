import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import Image, { StaticImageData } from 'next/image'
import Link from 'next/link'
import { FC, useMemo } from 'react'

import Icon from '@promova/ui/components/Icon/Icon'

import germanyImage from '@images/flags_3d/germany.png'
import koreaImage from '@images/flags_3d/korea.png'
import spainImage from '@images/flags_3d/spain.png'
import ukraineImage from '@images/flags_3d/ukraine.png'
import usImage from '@images/flags_3d/us.png'

import styles from './flag_section.module.scss'

type FlagItem = {
  src: StaticImageData | null
  label:
    | 'English'
    | 'German'
    | 'Ukrainian'
    | 'Spanish'
    | 'Korean'
    | 'More...'
    | string
}

type Props = {
  link: string
  handleLinkClick: () => void
  isScrolling: boolean
}

const flagItems: Array<FlagItem> = [
  {
    src: usImage,
    label: t`English`,
  },
  {
    src: germanyImage,
    label: t`German`,
  },
  {
    src: ukraineImage,
    label: t`Ukrainian`,
  },
  {
    src: spainImage,
    label: t`Spanish`,
  },
  {
    src: koreaImage,
    label: t`Korean`,
  },
  {
    src: null,
    label: t`More...`,
  },
]

const FlagsSection: FC<Props> = (props) => {
  const { link, handleLinkClick, isScrolling } = props

  const plusIcon = useMemo(() => <Icon icon="plus_circle" size={86} />, [])

  const flagItemClassNames = classNames(styles.flag_item, {
    [styles.flag_item_scrolling]: isScrolling,
  })

  return (
    <>
      {flagItems.map((item) => {
        if (item.label === 'More...') {
          return (
            <div
              key={item.label}
              className={flagItemClassNames}
              onClick={handleLinkClick}
              onKeyDown={() => undefined}
              role="button"
              tabIndex={0}
            >
              <Link href={link}>
                <a>
                  {plusIcon}
                  <div className={styles.flag_label} role="presentation">
                    {i18n._(item.label)}
                  </div>
                </a>
              </Link>
            </div>
          )
        }

        return (
          <div
            key={item.label}
            className={flagItemClassNames}
            onClick={handleLinkClick}
            onKeyDown={() => undefined}
            role="button"
            tabIndex={0}
          >
            <Link href={link}>
              <a>
                <figure>
                  {item.src && <Image src={item.src} alt={item.label} />}
                  <figcaption className={styles.flag_label} role="presentation">
                    {i18n._(item.label)}
                  </figcaption>
                </figure>
              </a>
            </Link>
          </div>
        )
      })}
    </>
  )
}

export default FlagsSection
