import { parseISO } from 'date-fns'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useMemo } from 'react'

import { CtaTimerWithButtonAllPagesType } from '@_types/strapi/singleTypes'

import { useGeoCtaTimerWithButtonQuery } from '@hooks/dataFetching/CTA/useGeoCtaTimerWithButtonQuery'
import useLandingInfo from '@hooks/useLandingInfo'

import { shouldRenderCTAByIncludedExcludedUrls } from '@utils/shouldRenderCTAByIncludedExcludedUrls'

import CtaTimerWithButtonAllPages from '@modules/CTA/CtaTimerWithButtonAllPages/CtaTimerWithButtonAllPages'
import { useMountedCtaContext } from '@modules/CTA/MountedCtaContext'

interface Props {
  isScroll: boolean
  location: 'top' | 'bottom'
}

const CtaTimerWithButtonAllPagesWrapper: FC<Props> = ({
  isScroll,
  location,
}) => {
  const { locale } = useRouter()
  const { LANDING_PATH } = useLandingInfo()

  const {
    data: dataCtaTimers,
    isLoading: isLoadingCtaTimer,
    isError: isErrorCtaTimer,
  } = useGeoCtaTimerWithButtonQuery({ locale })

  const selectedBanner = useMemo(() => {
    if (isLoadingCtaTimer || isErrorCtaTimer || !dataCtaTimers?.length) {
      return null
    }

    return dataCtaTimers.find((dataCtaTimer) => {
      const isExpired =
        dataCtaTimer?.attributes?.timer?.dateEnd &&
        new Date() > parseISO(dataCtaTimer.attributes.timer.dateEnd)

      const isPopupEnabled = !dataCtaTimer?.attributes?.isDisabled

      const isCTAVisibleBasedOnUrl = shouldRenderCTAByIncludedExcludedUrls({
        currentUrl: LANDING_PATH,
        includedUrls: dataCtaTimer?.attributes?.urlsToInclude || [],
        excludedUrls: dataCtaTimer?.attributes?.urlsToExclude || [],
      })

      return isPopupEnabled && isCTAVisibleBasedOnUrl && !isExpired
    })?.attributes
  }, [dataCtaTimers, isLoadingCtaTimer, isErrorCtaTimer, LANDING_PATH])

  const mountedCtaContext = useMountedCtaContext()

  useEffect(() => {
    if (mountedCtaContext) {
      mountedCtaContext.setCtaWithTimer(!!selectedBanner)
    }
  }, [mountedCtaContext, selectedBanner])

  const isPopupLocationValid = selectedBanner?.location === location

  if (!selectedBanner || !isPopupLocationValid) return null

  return (
    <CtaTimerWithButtonAllPages
      data={selectedBanner as CtaTimerWithButtonAllPagesType}
      isScroll={isScroll}
    />
  )
}

export default CtaTimerWithButtonAllPagesWrapper
