import {
  CTA_GEO_TIMER_WITH_BUTTON,
  DEFAULT_LOCALE,
  StrapiCtaBannerList,
} from '@constants'

import {
  CtaGeoTimerWithButtonStrapiRes,
  CtaTimerWithButtonAllPagesType,
} from '@_types/strapi/singleTypes'

import { useCtaBannerListQuery } from '@hooks/dataFetching/CTA/useCtaBannerListQuery'
import { CTA_REFETCH_ATTEMPTS, HOURS_24 } from '@hooks/dataFetching/constants'
import { useQueryFactory } from '@hooks/dataFetching/useQueryFactory'
import { fetchStrapiViaProxy } from '@hooks/dataFetching/utils'

import { useQueryCountry } from '@utils/useQueryCountry'

const CTA_TIMER_WITH_BUTTON_POPULATE = ['timer.timerBgColor', 'button.icon']

type Props = {
  locale?: string | undefined
  enabled?: boolean
}

export const useGeoCtaTimerWithButtonQuery = ({
  locale = DEFAULT_LOCALE,
  enabled = true,
}: Props) => {
  const { countryCode } = useQueryCountry()

  const queryParams = {
    populate: CTA_TIMER_WITH_BUTTON_POPULATE,
    locale,
    filters: {
      countries: {
        $containsi: countryCode,
      },
    },
  }

  const geoRes = useQueryFactory(
    // Include all params that affect the query result in the queryKey
    [CTA_GEO_TIMER_WITH_BUTTON, locale, countryCode, queryParams],
    (): Promise<CtaGeoTimerWithButtonStrapiRes> =>
      fetchStrapiViaProxy({
        url: CTA_GEO_TIMER_WITH_BUTTON,
        urlParamsObject: queryParams,
      })
  )({
    enabled: enabled && !!countryCode,
    cacheTime: HOURS_24,
    staleTime: HOURS_24,
    retry: CTA_REFETCH_ATTEMPTS,
    select: (data) => data?.data,
  })

  const defaultQueryParams = {
    slug: StrapiCtaBannerList.CTA_TIMER_WITH_BUTTON_ALL_PAGES,
    locale,
  }

  const defaultRes = useCtaBannerListQuery<CtaTimerWithButtonAllPagesType>({
    ...defaultQueryParams,
    enabled,
  })

  const defaultResData = { attributes: defaultRes.data }
  const data = geoRes.data ? [...geoRes.data, defaultResData] : [defaultResData]

  return {
    data,
    isLoading: geoRes.isLoading || defaultRes.isLoading,
    isError: geoRes.isError || defaultRes.isError,
  }
}
